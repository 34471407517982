import { Component, OnInit } from '@angular/core';
import { SalesInteractionService } from './sales-interaction.service';

@Component({
  selector: 'app-a-pointofsale-window',
  templateUrl: './a-pointofsale-window.component.html',
  styleUrls: ['./a-pointofsale-window.component.css']
})
export class APointofsaleWindowComponent implements OnInit {
  salesamount:number;
  constructor(private salesInteractionService:SalesInteractionService) { }

  ngOnInit() {
    if(localStorage.getItem("userRole")=="admin")
    {
      this.salesInteractionService.getdailysalesamount().subscribe((data)=>{
        this.salesamount = parseFloat(data.amount);})
    }
    else
    {
      this.salesInteractionService.getsalesamount(localStorage.getItem("username")).subscribe((data)=>{      
        this.salesamount = parseFloat(data.amount);})
    }
  }

}
