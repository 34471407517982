import { Injectable } from '@angular/core';
import { Inventory } from './inventory.model';
import { apiURL } from "../../global-variables"


import { Subject } from 'rxjs';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

const InventorySchema = '../../../../backend/models/inventory.js';

@Injectable({
  providedIn: 'root'
})

export class InventoryInteractionService {

  private inventory: Inventory[] = [];
  private inventoryUpdated = new Subject<Inventory[]>();

  private inventoryi: Inventory[] = [];
  private inventoryUpdatedi = new Subject<Inventory[]>();

  private inventor = [];
  private inventoryUpdate = new Subject<any[]>();

  private inventorex = [];
  private inventoryUpdateex = new Subject<any[]>();

  private inventorot = [];
  private inventoryUpdateot = new Subject<any[]>();

  private inventoraex = [];
  private inventoryUpdateaex = new Subject<any[]>();

  private inventoraot = [];
  private inventoryUpdateaot = new Subject<any[]>();



  constructor(private http: HttpClient, private router : Router){}

  getInventory(itemsPerPage: number , currentPage:number) {
    const queryParams = `?pagesize=${itemsPerPage}&page=${currentPage}`;
    this.http.get<{message: string, inventorys: any}>(apiURL+'inventory' + queryParams)
    .pipe(map(inventoryData => {
     return inventoryData.inventorys.map(inventory=>{
       return{
        id: inventory._id,
        supplier: inventory.supplier,
        name: inventory.name,
        salt: inventory.salt,
        hsncode: inventory.hsncode,
        mfrs: inventory.mfrs,        
        batchid:inventory.batchid,
        purprice:inventory.purprice,
        price: inventory.price,
        mrp: inventory.mrp,
        quantity:inventory.quantity,
        pkgquant: inventory.pkgquant,
        discount: inventory.discount,
        tax: inventory.tax,
        expireDate: new Date(inventory.expireDate).toISOString().split('T')[0],
        rack: inventory.rack,
        invoice:inventory.invoice,
        imagePath:  inventory.imagePath
       }
     })
    }))
    .subscribe((transformedInventory)=>{
      this.inventory = transformedInventory;
      this.inventoryUpdated.next([...this.inventory])
    });

  }
  getPatients() {
    return this.http.get<{message: string, patients: any}>(apiURL+'patients');
  }

  getOutofStockInventory() {

    this.http.get<{message: string, inventorys: any}>(apiURL+'inventory/outofstock' )
    .pipe(map(inventoryData => {
     return inventoryData.inventorys.map(inventory=>{
       return{        
        id: inventory._id,
        supplier: inventory.supplier,
        name: inventory.name,
        hsncode: inventory.hsncode,
        mfrs: inventory.mfrs,        
        batchid:inventory.batchid,
        purprice:inventory.purprice,
        price: inventory.price,
        mrp: inventory.mrp,
        quantity:inventory.quantity,
        pkgquant: inventory.pkgquant,
        discount: inventory.discount,
        tax: inventory.tax,
        expireDate: new Date(inventory.expireDate).toISOString().split('T')[0],
        rack: inventory.rack,
        invoice:inventory.invoice,
        imagePath:  inventory.imagePath
       }
     })
    }))
    .subscribe((transformedInventory)=>{
      this.inventorot = transformedInventory;
      this.inventoryUpdateot.next([...this.inventorot])
    });
  }


  getAboutToOutofStockInventory() {

    this.http.get<{message: string, inventorys: any}>(apiURL+'inventory/abouttooutofstock' )
    .pipe(map(inventoryData => {
     return inventoryData.inventorys.map(inventory=>{
       return{
        id: inventory._id,
        supplier: inventory.supplier,
        name: inventory.name,
        hsncode: inventory.hsncode,
        mfrs: inventory.mfrs,        
        batchid:inventory.batchid,
        purprice:inventory.purprice,
        price: inventory.price,
        mrp: inventory.mrp,
        quantity:inventory.quantity,
        pkgquant: inventory.pkgquant,
        discount: inventory.discount,
        tax: inventory.tax,
        expireDate: new Date(inventory.expireDate).toISOString().split('T')[0],
        rack: inventory.rack,
        invoice:inventory.invoice,
        imagePath:  inventory.imagePath
       }
     })
    }))
    .subscribe((transformedInventory)=>{
      this.inventoraot = transformedInventory;
      this.inventoryUpdateaot.next([...this.inventoraot])
    });
  }


  getExpiredInventory(){
    this.http.get<{message: string, inventorys: any}>(apiURL+'inventory/getExpired')
    .pipe(map(inventoryData => {
     return inventoryData.inventorys.map(inventory=>{
       return{
        id: inventory._id,
        supplier: inventory.supplier,
        name: inventory.name,
        hsncode: inventory.hsncode,
        mfrs: inventory.mfrs,        
        batchid:inventory.batchid,
        purprice:inventory.purprice,
        price: inventory.price,
        mrp: inventory.mrp,
        quantity:inventory.quantity,
        pkgquant: inventory.pkgquant,
        discount: inventory.discount,
        tax: inventory.tax,
        expireDate: new Date(inventory.expireDate).toISOString().split('T')[0],
        rack: inventory.rack,
        invoice:inventory.invoice,
        imagePath:  inventory.imagePath
       }
     })
    }))
    .subscribe((transformedInventory)=>{
      this.inventorex = transformedInventory;
      this.inventoryUpdateex.next([...this.inventorex])
    });
  }

  getAboutToExpireInventory(){
    let currentDate = new Date();

    this.http.get<{message: string, inventorys: any}>(apiURL+'inventory/getAboutToExpire')
    .pipe(map(inventoryData => {
     return inventoryData.inventorys.map(inventory=>{
       return{
        id: inventory._id,
        supplier: inventory.supplier,
        name: inventory.name,
        hsncode: inventory.hsncode,
        mfrs: inventory.mfrs,        
        batchid:inventory.batchid,
        purprice:inventory.purprice,
        price: inventory.price,
        mrp: inventory.mrp,
        quantity:inventory.quantity,
        pkgquant: inventory.pkgquant,
        discount: inventory.discount,
        tax: inventory.tax,
        expireDate: new Date(inventory.expireDate).toISOString().split('T')[0],
        rack: inventory.rack,
        invoice:inventory.invoice,
        imagePath:  inventory.imagePath

       }
     })
    }))
    .subscribe((transformedInventory)=>{
      this.inventoraex = transformedInventory;
      this.inventoryUpdateaex.next([...this.inventoraex])
    });
  }



  // getItemsOfId(id: string){
  //   this.http.get<{message: string, inventorys: any}>(apiURL+'inventory/' + id)
  //   .pipe(map(inventoryData => {
  //     return inventoryData.inventorys.map(inventory=>{
  //       return{
  //        name: inventory.name,
  //        quantity:inventory.quantity,
  //        batchId:inventory.batchId,
  //        expireDate: inventory.expireDate,
  //        id: inventory._id,
  //        imagePath:  inventory.imagePath
  //       }
  //     })
  //    }))
  //     .subscribe(() =>{
  //       const inventoryUpdated = this.inventory.filter(inventory => inventory.id !== id);
  //       this.inventory = inventoryUpdated;
  //       this.inventoryUpdated.next([...this.inventory])
  //     });
  //   }

  getInventoryExUpdateListener() {
    return this.inventoryUpdateex.asObservable();
  }

  getInventoryOutUpdateListener() {
    return this.inventoryUpdateot.asObservable();
  }

  getInventoryAExUpdateListener() {
    return this.inventoryUpdateaex.asObservable();
  }

  getInventoryAOutUpdateListener() {
    return this.inventoryUpdateaot.asObservable();
  }


  getInventoryUpdateListener() {
    return this.inventoryUpdated.asObservable();
  }

  getInventorys(id: string){
    return this.http.get<{_id: string, supplier: string, name: string,salt: string, hsncode: string, mfrs: string, batchid: string, purprice:string, price: string, mrp: string, quantity: string, pkgquant:string,discount:string, tax:string, expireDate: string, rack:string,invoice:string, imagePath:string}>
    (apiURL+'inventory/' + id);
  }

  uploadinventory(data:any)
  {
    return this.http.post<{message: string}>(apiURL+'inventory/uploadbulk',data);
  }

  addInventory( supplier: string, name: string,salt: string, hsncode: string, mfrs: string, batchid: string,purprice:string, price: string, mrp: string, quantity: string, pkgquant:string,discount:string, tax:string, expireDate: string, rack: string,invoice:string, image: File) {
    const inventoryData = new FormData();
    inventoryData.append("supplier", supplier);
    inventoryData.append("name", name); 
    inventoryData.append("salt", salt);    
    inventoryData.append("hsncode", hsncode); 
    inventoryData.append("mfrs", mfrs); 
    inventoryData.append("batchid", batchid);    
    inventoryData.append("purprice", purprice);
    inventoryData.append("price", price);
    inventoryData.append("mrp", mrp);
    inventoryData.append("quantity", quantity);
    inventoryData.append("pkgquant", pkgquant);
    inventoryData.append("discount", discount);
    inventoryData.append("tax", tax);
    inventoryData.append("expireDate", expireDate);
    inventoryData.append("rack", rack);
    inventoryData.append("invoice", invoice);
    inventoryData.append("image", image, name);

    this.http.post<{message: string, inventory: Inventory}>(apiURL+'inventory',inventoryData)
    .subscribe((responseData)=>{
      const inventory: Inventory ={id: responseData.inventory.id,
                                   supplier:supplier ,
                                   name:name ,
                                   salt:salt ,
                                   hsncode:hsncode ,
                                   mfrs:mfrs ,
                                   batchid: batchid ,
                                   purprice:purprice,                                   
                                   price: price,
                                   mrp: mrp,
                                   quantity: quantity,
                                   pkgquant:pkgquant,
                                   discount:discount,
                                   tax:tax,
                                   expireDate: expireDate ,
                                   rack: rack ,
                                   invoice:invoice,
                                   imagePath : responseData.inventory.imagePath};

      this.inventory.push(inventory);
      this.inventoryUpdated.next([...this.inventory]);
      this.router.navigate(["/inventory/"]);
    });

  }

  updateInventory(id: string , supplier: string, name: string,salt: string, hsncode: string, mfrs: string, batchid: string,purprice:string, price: string, mrp: string, quantity: string, pkgquant:string,discount:string, tax:string, expireDate: string, rack: string,invoice:string, image: File) {

    let inventoryData: Inventory | FormData;

    if (typeof(image)==='object'){
      inventoryData = new FormData();
      inventoryData.append("id", id);
      inventoryData.append("supplier", supplier);
      inventoryData.append("name", name); 
      inventoryData.append("salt", salt);   
      inventoryData.append("hsncode", hsncode); 
      inventoryData.append("mfrs", mfrs); 
      inventoryData.append("batchid", batchid);    
      inventoryData.append("purprice", purprice);
      inventoryData.append("price", price);
      inventoryData.append("mrp", mrp);
      inventoryData.append("quantity", quantity);
      inventoryData.append("pkgquant", pkgquant);
      inventoryData.append("discount", discount);
      inventoryData.append("tax", tax);
      inventoryData.append("expireDate", expireDate);
      inventoryData.append("rack", rack);
      inventoryData.append("invoice", invoice);
      inventoryData.append("image", image, name);

    } else{
       inventoryData  ={id : id ,
        supplier:supplier ,
        name:name ,
        salt:salt ,
        hsncode:hsncode ,
        mfrs:mfrs ,
        batchid: batchid ,
        purprice:purprice,                                   
        price: price,
        mrp: mrp,
        quantity: quantity,
        pkgquant:pkgquant,
        discount:discount,
        tax:tax,
        expireDate: expireDate ,
        rack: rack ,
        invoice:invoice,
        imagePath: image};
    }    this.http
             .put(apiURL+'inventory/' + id , inventoryData)
             .subscribe(response => {
               const updatedInventorys = [...this.inventory];
               const oldInventoryIndex = updatedInventorys.findIndex(i => i.id === id);

               const inventory : Inventory ={id : id ,
                supplier:supplier ,
                name:name ,
                salt:salt ,
                hsncode:hsncode ,
                mfrs:mfrs ,
                batchid: batchid ,
                purprice:purprice,                                   
                price: price,
                mrp: mrp,
                quantity: quantity,
                pkgquant:pkgquant,
                discount:discount,
                tax:tax,
                expireDate: expireDate ,
                rack: rack ,
                invoice:invoice,
                imagePath: " "};
               updatedInventorys[oldInventoryIndex] = inventory;
               this.inventoryUpdated.next([...this.inventory]);
               this.router.navigate(["/inventory/"]);
             });
  }


  updateQuantity(id: string ,quantity: number){
    const inventory   ={id:id ,quantity:quantity };
    this.http
             .put(apiURL+'inventory/updateQuantity/' + id , inventory)
             .subscribe(response => {
               const updatedInventory = [...this.inventor];
               const oldInventoryIndex = updatedInventory.findIndex(s => s.id ===inventory.id);
               updatedInventory[oldInventoryIndex] = inventory;
               this.inventoryUpdate.next([...this.inventor]);
               //this.router.navigate(["/suppliers/create"]);
             });
  }

  deleteInventory(inventoryId: string) {
    this.http.delete(apiURL+'inventory/' + inventoryId)
      .subscribe(() =>{
        const inventoryUpdated = this.inventory.filter(inventory => inventory.id !== inventoryId);
        this.inventory = inventoryUpdated;
        this.inventoryUpdated.next([...this.inventory])
      });
  }
}
