import { MatSnackBar } from '@angular/material';
import { ExpensesInteractionService } from '../../expense-interaction.service';
import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Expense } from '../../expense.model';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-list-expenses-items',
  templateUrl: './list-expenses-items.component.html',
  styleUrls: ['./list-expenses-items.component.css']
})
export class ListExpensesItemsComponent implements OnInit {
  searchTerm:string;
  expenses : Expense[] = [];
  isLoading= false;
  private expensesSubs: Subscription;

  constructor(private expensesInteractionService: ExpensesInteractionService, private snackBar :MatSnackBar) { }

  ngOnInit() {
    this.isLoading = true;
    this.expensesInteractionService.getExpenses(null,null);
    this.expensesSubs = this.expensesInteractionService.getExpenseUpdateListener()
      .subscribe((posts: Expense[]) => {
        this.isLoading = false;
        this.expenses = posts;
      });
  }


  onDelete(expenseId: string) {
    this.expensesInteractionService.deleteExpense(expenseId);
    this.snackBar.open("Expense Deleted Successfully", "Close",{duration: 10000});
  }

  ngOnDestroy() {
    this.expensesSubs.unsubscribe();
  }
  downloadcsv() {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(this.expenses[0]);
    let csv = this.expenses.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer).replace(",","#")).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], {type: 'text/csv' })
    saveAs(blob, "myFile.csv");
}

}
