<div class="columns is-multiline top ">
  <div class="column  ">
    <div style="border: none;border-radius: 10px;" class="box notification fill-color" >
      <div class="heading spacing">Online Stats</div>
      <iframe style="margin-left: 7px;background: #21313C;border: none;border-radius: 10px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);" width="640" height="480" src="https://charts.mongodb.com/charts-project-0-logtf/embed/charts?id=6dcb88d4-310b-4a3c-a55b-21af76fc952c&autoRefresh=10&theme=dark"></iframe>
      <div class="level">
        <div class="level-item">
          <div class="">
            <div class="heading ">Sales $</div>
            <div class="title is-5">250,000</div>
          </div>
        </div>
        <div class="level-item">
          <div class="">
            <div class="heading ">Overall $</div>
            <div class="title is-5 ">750,000</div>
          </div>
        </div>
        <div class="level-item">
          <div class="">
            <div class="heading ">Sales %</div>
            <div class="title is-5 ">25%</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="column ">
    <div style="border: none;border-radius: 10px;" class="box notification fill-color ">
      <div class="heading">Revenue / Expenses</div>
      <iframe style="margin-left: 7px;background: #21313C;border: none;border-radius: 10px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);" width="640" height="480" src="https://charts.mongodb.com/charts-project-0-logtf/embed/charts?id=7d47ee89-9160-4c68-acf1-3814c477c9e6&autoRefresh=10&theme=dark"></iframe>
      <div class="level">
        <div class="level-item">
          <div class="">
            <div class="heading">Rev Prod $</div>
            <div class="title is-5">30%</div>
          </div>
        </div>
        <div class="level-item">
          <div class="">
            <div class="heading">Rev Serv $</div>
            <div class="title is-5">25%</div>
          </div>
        </div>
        <div class="level-item">
          <div class="">
            <div class="heading">Exp %</div>
            <div class="title is-5">45%</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="column ">
    <div style="border: none;border-radius: 10px;" class="box notification fill-color ">
      <div class="heading">Feedback Activity</div>
      <iframe style="margin-left: 7px; background: #21313C;border: none;border-radius: 10px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);" width="640" height="480" src="https://charts.mongodb.com/charts-project-0-logtf/embed/charts?id=cbc7e083-861b-41c1-aae7-a757f482a93e&autoRefresh=10&theme=dark"></iframe>
      <div class="level">
        <div class="level-item">
          <div class="">
            <div class="heading">Positive</div>
            <div class="title is-5">1560</div>
          </div>
        </div>
        <div class="level-item">
          <div class="">
            <div class="heading">Negative</div>
            <div class="title is-5">368</div>
          </div>
        </div>
        <div class="level-item">
          <div class="">
            <div class="heading">Pos/Neg %</div>
            <div class="title is-5">77% / 23%</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="column ">
    <div style="border: none;border-radius: 10px;" class="box notification fill-color ">
      <div class="heading">Feedback Activity</div>
      <iframe style="margin-left: 7px;background: #21313C;border: none;border-radius: 10px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);" width="640" height="480" src="https://charts.mongodb.com/charts-project-0-logtf/embed/charts?id=7ece1086-34aa-4844-92e5-2451e16f4d8f&autoRefresh=10&theme=dark"></iframe>
      <div class="level">
        <div class="level-item">
          <div class="">
            <div class="heading">Positive</div>
            <div class="title is-5">1560</div>
          </div>
        </div>
        <div class="level-item">
          <div class="">
            <div class="heading">Negative</div>
            <div class="title is-5">368</div>
          </div>
        </div>
        <div class="level-item">
          <div class="">
            <div class="heading">Pos/Neg %</div>
            <div class="title is-5">77% / 23%</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<style>
  .fill-color{
    background-color: hsl(171, 55%, 76%);

  }
  .top{
    margin-top: 3%;
  }
  .side{
    padding-left: 5%;
  }

</style>
