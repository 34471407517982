<div class="container" style="background-color: #041221;max-width: none;margin-top: -1%;">
  <div id="flow">
      <span class="flow-1"></span>
      <span class="flow-2"></span>
      <span class="flow-3"></span>
  </div>
  <div class="section" >

    <section class="hero is-success is-fullheight">
      <div class="hero-body">
          <div class="container has-text-centered" style="max-width: 1132px;">
              <div class="column is-4 is-offset-4">
                <h3 class="title ">One Stop Pharmacy</h3>
                  <h3 class="title ">Login</h3>
                  <hr class="login-hr">

                  <div class="box">
                      <figure class="avatar">
                          <img src="/assets/images/user_icon.png" width="120">
                      </figure>


                      <form (submit)="onLogin(loginForm)" #loginForm="ngForm">
                          <div class="field">
                              <div class="control">

                                  <input name="email" ngModel #emailInput="ngModel" class="input is-medium" type="email" placeholder="Your Email" autofocus="" required email>
                                  <p class="login-error" *ngIf="!emailInput.valid && emailInput.touched">Please enter valid email</p>

                              </div>
                          </div>

                          <div class="field">
                              <div class="control">

                                  <input name="password" ngModel #passwordInput="ngModel" class="input is-medium" type="password" placeholder="Your Password" required>
                                  <p class="login-error" *ngIf="!passwordInput.valid && passwordInput.touched">Please enter valid password</p>

                              </div>
                          </div>
                          <div class="field">
                              <label class="checkbox">
                              <input type="checkbox">
                              Remember me
                            </label>
                          </div>
                          <button class="button is-block is-primary is-medium is-fullwidth" type="submit">Login <i class="fas fas-sign-in" aria-hidden="true"></i></button>
                      </form>


                  </div>
                  <p class="has-text-grey" style="color: #00d1b2;">
                      <!-- <a style="color: #00d1b2;" routerLink="/signup">Sign Up</a> &nbsp;·&nbsp; -->
                      <a style="float:left;padding-left:10px;color: #00d1b2;" routerLink="/forgotpassword">Forgot Password</a>
                      <a style="float:right;padding-right:10px;color: #00d1b2;" href="/help">Need Help?</a>
                  </p><br>
                  <!-- <p class="subtitle ">Please login to proceed.</p> -->
                  <a class=" level-item logo-margin" >
                    <img src="/assets/images/Logo1.png" width="200" height="180">
                  </a>
              </div>
          </div>
      </div>
    </section>

  </div>
</div>
