
<div style="margin-top: 5%;">


  <div style="margin-top: 2%; height:900px">
    <section class="section columns">
      <div class="column is-full-desktop is-full-mobile">
        <!-- <app-search-supplier-window></app-search-supplier-window> -->
        <div class="tabs is-toggle is-fullwidth">
          <ul>
            <li >
              <a routerLink="/settings">
                <!-- <span class="icon is-small"><i class="fas fa-music" aria-hidden="true"></i></span> -->
                <span>Add New Accounts</span>
              </a>
            </li>
            <li >
              <a routerLink="/settings/DoctorAccount">
                <!-- <span class="icon is-small"><i class="fas fa-image" aria-hidden="true"></i></span> -->
                <span>Manage Doctor Account</span>
              </a>
            </li>
            <li >
              <a routerLink="/settings/APharmasistAccounts">
                <!-- <span class="icon is-small"><i class="fas fa-music" aria-hidden="true"></i></span> -->
                <span>Manage Pharmacy User Account</span>
              </a>
            </li>
            <li class="is-active">
              <a routerLink="/settings/CashierAccounts">
                <!-- <span class="icon is-small"><i class="fas fa-music" aria-hidden="true"></i></span> -->
                <span>View User Statictics</span>
              </a>
            </li>


          </ul>
        </div>
      </div>
    </section>
  <section class="panel is-left is-half" style="margin: 3px;margin-top: -30px; width: 48%;">
    <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
      Pharmacy User Stats
    </p>
    <div class="panel-block ">
      <div  style="height: 610px; width: 115%;">




        <iframe style="background: #21313C;border: none;border-radius: 10px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);height: 600px;" width="640" height="480" src="https://charts.mongodb.com/charts-project-0-logtf/embed/charts?id=83e778c6-c902-4d70-b9c2-7d1ba9992928&autoRefresh=10&theme=dark"></iframe>



      </div>
    </div>
    <div class="panel-block">

    </div>
  </section>
  <section class="panel is-right is-half" style="margin: 3px;margin-top: -44.85%;margin-left: 51%; width: 48%;">
    <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
      Doctor Account Stats
    </p>
    <div class="panel-block ">
      <div  style="height: 610px; width: 115%;">



        <iframe style="background: #21313C;border: none;border-radius: 10px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2); height: 600px;" width="640" height="480" src="https://charts.mongodb.com/charts-project-0-logtf/embed/charts?id=1dd7717a-00ee-4a13-b810-9603cee5d959&autoRefresh=10&theme=dark"></iframe>

      </div>
    </div>
    <div class="panel-block">

    </div>
  </section>
  </div>

  </div>
