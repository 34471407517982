<section class="panel" >
  <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
    Drug Quantity Chart
  </p>
  <div class="panel-block ">
    <div  style="height: 570px; width: 100%;">
      <div class="column">
        <iframe style="background: #21313C;border: none;border-radius: 7px;height: 550px;width: 100%;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);" width="690" height="690" src="https://charts.mongodb.com/charts-project-0-logtf/embed/charts?id=a81c330b-990d-4458-959a-a4cfb03daee6&autoRefresh=10&theme=dark"></iframe>
      </div>
    </div>
  </div>
  <div class="panel-block">
    <button class="button is-primary   is-fullwidth">
      View the quantity availabilty of Drugs
    </button>
  </div>
</section>



