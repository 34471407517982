import { AuthService } from '../auth.service';
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBarModule, MatSnackBar } from '@angular/material';

@Component({
   templateUrl: './help.component.html',
   styleUrls: ['./help.component.css']
})
export class HelpComponent {

  constructor(public authService : AuthService, private sankBar : MatSnackBar){}

  onHelp(form:  NgForm){
    if(form.invalid){
      this.sankBar.open("Please enter valid data", 'Close',{duration: 10000});
      return;

    }
    this.sankBar.open("Logging... Please wait ", 'Close',{duration: 10000});    
    
    this.authService.help(form.value.email,form.value.text);
  };

};
