<section class="panel">
  <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
    Check Out
  </p>
  <div class="panel-block ">
    <div  style="height: 300px; width: 100%;">
      <br>
      <app-check-out-elements></app-check-out-elements>

    </div>
  </div>
  <div class="panel-block">
    <button class="button is-primary   is-fullwidth">
      Print Bill
    </button>
  </div>
</section>
