<div style="margin-top: 5%;">
  <!-- <section class="section columns">
    <div class="column is-full-desktop is-full-mobile">
      <app-search-inventory></app-search-inventory>
    </div>
  </section> -->
  <section class="section columns is-centered">
    <div class="column is-full-desktop is-full-mobile">
      <app-add-inventory-window></app-add-inventory-window>
    </div>
  </section>
  <!-- <section class="section columns">
    <div class="column is-full-desktop is-full-mobile">
      <app-drug-inventory-window></app-drug-inventory-window>
    </div>
  </section> -->
</div>
