import { MatSnackBar } from '@angular/material';
import {  FormGroup, FormControl, Validators } from '@angular/forms';
import { ExpensesInteractionService } from '../../expense-interaction.service';
import { Expense } from '../../expense.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-add-expenses-elements',
  templateUrl: './add-expenses-elements.component.html',
  styleUrls: ['./add-expenses-elements.component.css']
})
export class AddExpensesElementsComponent implements OnInit {
  expense : Expense ;
  isLoading = false;
  form: FormGroup;
  private mode = "create";
  private expenseId : string;


  constructor(private expensesInteractionService: ExpensesInteractionService, public route: ActivatedRoute , private snackBar: MatSnackBar){}

  ngOnInit() {
    this.form = new FormGroup({
      'detail': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'amount': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'expdate': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'billno': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
    });
    this.route.paramMap.subscribe((paramMap: ParamMap)=>{
      if (paramMap.has('expenseId')){
        this.mode = "edit";
        this.expenseId = paramMap.get('expenseId');
        this.isLoading = true;
        this.expensesInteractionService.getExpense(this.expenseId).subscribe(expenseData =>{
        this.isLoading = false;
        this.expense = {id:expenseData._id,
          detail: expenseData.detail,
          amount: expenseData.amount,
          expdate: expenseData.expdate,
          billno: expenseData.billno,
          };
        this.form.setValue({'detail':this.expense.detail ,
                            'amount':this.expense.amount,  
                            'expdate':this.expense.expdate,                          
                            'billno':this.expense.billno});
        });
      }else{
        this.mode = "create";
        this.expenseId = null;
      }
    })
  }

  formatDate(date:Date) {
    return [      
      this.padTo2Digits(date.getMonth() + 1),
      this.padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join('/');
  }
  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  onAddExpense() {
    if (this.form.invalid) {
      this.snackBar.open("Please validate your input", "Close",{duration: 10000});
      return;
    }

    if(this.mode === "create"){
      this.expensesInteractionService.addExpenses(this.form.value.detail,this.form.value.amount,this.form.value.expdate,
        this.form.value.billno
        );

        this.snackBar.open("Drug Added Successfully", "Close",{duration: 10000});
    }else{
      this.expensesInteractionService.updateExpense(this.expenseId,this.form.value.detail,this.form.value.amount,this.form.value.expdate,
        this.form.value.billno);

        this.snackBar.open("Drug Edited Successfully", "Close",{duration: 10000});
    }

    this.form.reset();
  }

}
