<div style=" overflow: hidden; " scroll="no">
<app-header *ngIf="showMenu && showSignup && showShoppingcart && showDoctorLogin && showDoctorSignup"></app-header>
<div class="columns" style="margin-top: 0%; ">
  <div class="column is-sidebar-menu is-hidden-mobile is-2" style="background-color:hsl(0, 0%, 29%) ;width: 15%;" *ngIf="showMenu  && showSignup && showShoppingcart && showDoctorLogin && showDoctorSignup">
    <app-sidemenu ></app-sidemenu>
  </div>
  <div class="column main " style="width:86%;margin-top:6%">
    <router-outlet></router-outlet>

  </div>
</div>
</div>

