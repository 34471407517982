

<div  class="bg-img" >
  <section class="section columns" style="margin-top: 4%;">
    <div class="column is-full-desktop is-full-mobile">
      <app-a-shopping-cart-items></app-a-shopping-cart-items>
    </div>
  </section>
</div>

