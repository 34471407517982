<div style="overflow-y:auto ;overflow-x: hidden;height: 500px;">
  <table class="table is-full menu-list"  >
    <tbody>
      <div class="" *ngFor="let docOder of docOders ">
        <tr >
          <div class="div columns  is-full btn r" style="width: 200%;" >
              <div class="div column" >
                <table class="table is-full" style="width: 60.5%;">
                  <thead style="font-weight: bold;">
                    <td>Doctor Name</td>
                    <td>Doctor Contact </td>
                    <td >DoctorID </td>
                    <td >Doctor Email </td>
                    <td >Drug Names </td>
                    <td>Drug Prices</td>
                    <td>Quantities</td>
                    <td >Total</td>
                    <td >Pickup Date</td>

                  </thead>
                  <tbody>
                    <td>{{docOder.doctorName}}</td>
                    <td>{{docOder.doctorContact}} </td>
                    <td >{{docOder.doctorId}} </td>
                    <td >{{docOder.doctorEmail}}</td>
                    <td >{{docOder.drugName[0]}}<br>{{docOder.drugName[1]}}<br>{{docOder.drugName[2]}}<br>{{docOder.drugName[3]}}<br>{{docOder.drugName[4]}}<br>{{docOder.drugName[5]}}</td>
                    <td>{{docOder.drugPrice[0]}}<br>{{docOder.drugPrice[1]}}<br>{{docOder.drugPrice[2]}}<br>{{docOder.drugPrice[3]}}<br>{{docOder.drugPrice[4]}}<br>{{docOder.drugPrice[5]}}</td>
                    <td>{{docOder.drugQuantity[0]}}<br>{{docOder.drugQuantity[1]}}<br>{{docOder.drugQuantity[2]}}<br>{{docOder.drugQuantity[3]}}<br>{{docOder.drugQuantity[4]}}<br>{{docOder.drugQuantity[5]}}  </td>
                    <td >{{docOder.totalAmount}} </td>
                    <td >{{docOder.pickupDate}} </td>

                  </tbody>
                </table>
              </div>
          </div>
        </tr>
        <tr style="width: 74.5%;">
          <div class=" " style="width: 119.6%;">
          <a class="button is-small is-primary is-right" style="font-weight: bold; " (click)="onPickup(docOder.doctorName,docOder.doctorEmail,docOder.totalAmount,docOder.pickupDate,docOder.drugId,docOder.drugName,docOder.drugPrice,docOder.drugQuantity,docOder.realQuantity,docOder.doctorId,docOder.doctorContact,docOder.id)">Picked Up</a>
        </div>
        </tr><br><br>
      </div>





    </tbody>
  </table>
  </div>
  <style>

    .menu-list{
      border-radius: 2px;
      width: 220%;
      display: block;
      padding: 10px;
      }
      .btn{
        width: 100%;
        color:hsl(0, 0%, 21%);
        background-color: hsl(0, 0%, 88%);
        border-radius: 3px;
      }
      .newclass :hover{
        background-color: hsl(0, 0%, 21%);
        color: hsl(0, 0%, 88%);
      }

  </style>
