<div style="overflow:auto ;height: 500px;width:100%">
  <table class="table is-full menu-list"  style="width: 100%;">    
      <div class="is-full" *ngFor="let inventory of inventorys">       
          <div class="div columns  is-full btn  " >
              <div class="div column" >
                <table class="table is-full">
                  <thead style="font-weight: bold;">
                    <td>SUPPLIER</td>
                    <td>DRUGNAME</td>
                    <td>HSBCODE</td>
                    <td>MFRS</td>
                    <td>BATCHID</td>
                    <td>PUR.PRICE</td>
                    <td>MRP</td>
                    <td>PRICE</td>
                    <td>QUANTITY</td>
                    <td>PKG.QUANTITY</td>
                    <td>DISCOUNT</td>
                    <td>TAX%</td>
                    <td>EXPIRY</td>                    
                    <td>RACK</td>
                    <td></td>
                  </thead>
                  <tbody>
                    <td>{{ inventory.supplier }}</td>
                    <td>{{ inventory.name }}</td>
                    <td>{{ inventory.hsncode }}</td>
                    <td>{{ inventory.mfrs }}</td>
                    <td>{{ inventory.batchid }}</td>
                    <td>{{ inventory.purprice }} </td>  
                    <td>{{ inventory.mrp }} </td>                  
                    <td>{{ inventory.price }} </td>
                    <td>{{ inventory.quantity }}</td>                    
                    <td>{{ inventory.pkgquant }} </td>
                    <td>{{ inventory.discount }} </td>
                    <td>{{ inventory.tax }} </td>
                    <td>{{ inventory.expireDate }} </td>
                    <td>{{ inventory.rack }}</td>
                    <td style="width: 1%;"> <a class="button is-small is-primary " style="font-weight: bold; " href="#">Picked Up</a>
                    </td>
                  </tbody>
                </table>
              </div>
              <div>
            </div>
          </div>
        <br>
        <!-- <tr>
          <div class=" " style="width: 134.5%;">
          <a class="button is-small is-primary is-right" style="font-weight: bold; " href="#">Picked Up</a>
        </div>
        </tr><br> -->
      </div>
  <br>    
  </table>
  </div>
  <style>

    .menu-list{
      border-radius: 2px;
      width: 220%;
      display: block;
      padding: 10px;
      }
      .btn{
        width: 100%;
        color:hsl(0, 0%, 21%);
        background-color: hsl(0, 0%, 88%);
        border-radius: 3px;
      }
      .newclass :hover{
        background-color: hsl(0, 0%, 21%);
        color: hsl(0, 0%, 88%);
      }

  </style>
