import { Injectable } from '@angular/core';
import { Expense } from './expense.model';
import { apiURL } from "../../global-variables";
import { Subject } from 'rxjs';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

const ExpenseSchema = '../../../../backend/models/expense.js';

@Injectable({
  providedIn: 'root'
})

export class ExpensesInteractionService {

  private expense: Expense[] = [];
  private expenseUpdated = new Subject<Expense[]>();

  // private inventoryi: Inventory[] = [];
  // private inventoryUpdatedi = new Subject<Inventory[]>();

  // private inventor = [];
  // private inventoryUpdate = new Subject<any[]>();

  // private inventorex = [];
  // private inventoryUpdateex = new Subject<any[]>();

  // private inventorot = [];
  // private inventoryUpdateot = new Subject<any[]>();

  // private inventoraex = [];
  // private inventoryUpdateaex = new Subject<any[]>();

  // private inventoraot = [];
  // private inventoryUpdateaot = new Subject<any[]>();



  constructor(private http: HttpClient, private router : Router){}

  getExpenses(itemsPerPage: number , currentPage:number) {
    const queryParams = `?pagesize=${itemsPerPage}&page=${currentPage}`;
    this.http.get<{message: string, expenses: any}>(apiURL+'expense' + queryParams)
    .pipe(map(expenseData => {
     return expenseData.expenses.map(expense=>{
       return{
        id: expense._id,
        detail: expense.detail,
        amount: expense.amount,
        expdate: expense.expdate,
        billno: expense.billno        
       }
     })
    }))
    .subscribe((transformedExpense)=>{
      this.expense = transformedExpense;
      this.expenseUpdated.next([...this.expense])
    });

  }

  getExpenseUpdateListener() {
    return this.expenseUpdated.asObservable();
  }

  getExpense(id: string){
    return this.http.get<{_id: string, detail:string,amount:string,expdate:string,billno:string}>
    (apiURL+'expense/' + id);
  }

  addExpenses( detail: string, amount: string,expdate: string, billno: string) {
    let expenseData:Expense | FormData;
    expenseData  ={id : '' ,
      detail:detail,
      amount:amount ,
      expdate:expdate ,
      billno:billno }; 
    
    this.http.post<{message: string, expense: Expense}>(apiURL+'expense',expenseData)
    .subscribe((responseData)=>{
      const expense: Expense ={id: responseData.expense.id,
                                   detail:detail,
                                   amount:amount,
                                   expdate:expdate,
                                   billno:billno};
      this.expense.push(expense);
      this.expenseUpdated.next([...this.expense]);
      this.router.navigate(["/expenses/"]);
    });

  }

  updateExpense(id: string , detail: string, amount: string,expdate: string, billno: string) {

    let expenseData: Expense | FormData;

    expenseData  ={id : id ,
      detail:detail,
      amount:amount ,
      expdate:expdate ,
      billno:billno };
    this.http
             .put(apiURL+'expense/' + id , expenseData)
             .subscribe(response => {
               const updatedExpenses = [...this.expense];
               const oldExpenseIndex = updatedExpenses.findIndex(i => i.id === id);

               const expense : Expense ={id : id ,
                detail:detail,
                amount:amount,
                expdate:expdate,
                billno:billno};
               updatedExpenses[oldExpenseIndex] = expense;
               this.expenseUpdated.next([...this.expense]);
               this.router.navigate(["/expenses/create"]);
             });
  }
  
  deleteExpense(expenseId: string) {
    this.http.delete(apiURL+'expense/' + expenseId)
      .subscribe(() =>{
        const expenseUpdated = this.expense.filter(expense => expense.id !== expenseId);
        this.expense = expenseUpdated;
        this.expenseUpdated.next([...this.expense])
      });
  }
}
