<section class="panel">
  <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
    Sales Information Chart
  </p>
  <div class="panel-block ">
    <div  style="height: 500px; width: 130%;">
      <iframe style="background: #21313C;border: none;height: 500px;;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);" width="640" height="580" src="https://charts.mongodb.com/charts-project-0-logtf/embed/charts?id=65fe4f0c-1dcb-4b7b-916c-e2d7bfdb7f1e&autoRefresh=10&theme=dark"></iframe>
    </div>
  </div>
  <div class="panel-block">
    <button class="button is-primary   is-fullwidth">
      View Sales Information
    </button>
  </div>
</section>


