<!-- includes the user profile photo , username , and title of the page -->
<div class="navbar-brand level-left">

  <a class="level-item logo-margin" href="./">
    <img src="/assets/images/Logo1.png" width="152" height="140">
  </a>

  <div id="navbarBasicExample" class="navbar-menu level-item logo-margin">

    <div class="navbar-start" style="margin-top: 6px;" *ngIf="AdminRole" >
      <div style="padding: 4px;margin-right: -10px;">
        <figure class="image is-48x48">
          <img class="is-rounded" src="https://bulma.io/images/placeholders/128x128.png">
        </figure>
      </div>
      <a class="navbar-item subtitle" style="color: azure;">
        Admin
      </a>
    </div>
    <div class="navbar-start" style="margin-top: 6px;" *ngIf="UserRole" >
      <div style="padding: 4px;margin-right: -10px;">
        <figure class="image is-48x48">
          <img class="is-rounded" src="https://bulma.io/images/placeholders/128x128.png">
        </figure>
      </div>
      <a class="navbar-item subtitle" style="color: azure;">
        Pharmacist
      </a>
    </div>

    <div class="navbar-start" style="margin-top: 6px;" *ngIf="CashierRole" >
      <div style="padding: 4px;margin-right: -10px;">
        <figure class="image is-48x48">
          <img class="is-rounded" src="https://bulma.io/images/placeholders/128x128.png">
        </figure>
      </div>
      <a class="navbar-item subtitle" style="color: azure;">
        Cashier
      </a>
    </div>


    <div class="navbar-start" style="margin-top: 6px;" *ngIf="ApharmacistRole" >
      <div style="padding: 4px;margin-right: -10px;">
        <figure class="image is-48x48">
          <img class="is-rounded" src="https://bulma.io/images/placeholders/128x128.png">
        </figure>
      </div>
      <a class="navbar-item subtitle" style="color: azure;">
        Assistant Pharmacist
      </a>
    </div>

  </div>

</div>

<style>
  .logo-margin{
    margin-left: 13%;
  }
</style>
