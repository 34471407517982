<section class="panel">
  <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
    About To Get Finished Notification
  </p>
  <div class="panel-block ">
    <div  style="height: 200px; width: 100%;">

      <app-about-to-outof-stock-items></app-about-to-outof-stock-items>

    </div>
  </div>
  <div class="panel-block">
    <button class="button is-primary   is-fullwidth">
      View Notifications
    </button>
  </div>
</section>
