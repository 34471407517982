<section class="panel">
  <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
    Bill
  </p>
  <div class="panel-block ">
    <div  style="height: 300px; width: 100%;">

      <app-bill-item></app-bill-item>

    </div>
  </div>
  <div class="panel-block">
    <button class="button is-primary   is-fullwidth">
      Check Out
    </button>
  </div>
</section>
<script src="https://unpkg.com/flickity@2/dist/flickity.pkgd.min.js"></script>
