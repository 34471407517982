<div style="margin-top: 5%; height:900px">
<section class="section columns">
  <div class="column is-full-desktop is-full-mobile">
    <!-- <app-search-supplier-window></app-search-supplier-window> -->
    <div class="tabs is-toggle is-fullwidth">
      <ul>
        <li class="is-active">
          <a routerLink="/suppliers/">
            <!-- <span class="icon is-small"><i class="fas fa-image" aria-hidden="true"></i></span> -->
            <span>Supplier Form</span>
          </a>
        </li>
        <li >
          <a routerLink="/suppliers/create">
            <!-- <span class="icon is-small"><i class="fas fa-music" aria-hidden="true"></i></span> -->
            <span>Supplier List</span>
          </a>
        </li>

      </ul>
    </div>
  </div>
</section>
<section class="panel" style="margin: 3%;margin-top: -3%;">
  <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
    Add Supplier
  </p>
  <div class="panel-block ">
    <div  style="height: 700px; width: 100%; padding: 2%;">

      <app-add-supplier-elements></app-add-supplier-elements>
    </div>
  </div>
</section>
</div>
