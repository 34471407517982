<div style="height:900px">
  <section class="section columns" style="padding-bottom:2px;padding-top: 15px;">
    <div class="column is-full-desktop is-full-mobile">
      <!-- <app-search-supplier-window></app-search-supplier-window> -->
      <div class="tabs is-toggle is-fullwidth">
        <ul>
          <li class="is-active ">
            <a routerLink="/expenses/create">
              <!-- <span class="icon is-small"><i class="fas fa-image" aria-hidden="true"></i></span> -->
              <span>Expenses Form</span>
            </a>
          </li>
          <li>
            <a routerLink="/expenses/">
              <!-- <span class="icon is-small"><i class="fas fa-music" aria-hidden="true"></i></span> -->
              <span>Expenses List</span>
            </a>
          </li>

        </ul>
      </div>
    </div>
  </section>
<section class="panel" style="margin: 1%;">
  <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
    Add Expense
  </p>
  <div class="panel-block ">
    <div  style="height: 667px; width: 100%;">

      <app-add-expenses-elements></app-add-expenses-elements>
    </div>
  </div>
  <div class="panel-block">
    <!-- <button class="button is-primary   is-fullwidth">
      Add
    </button> -->
  </div>
</section>
</div>
