
<section class="panel">
  <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
    Search Drugs
  </p>
  <div class="panel-block ">
    <div  style="height: 50px; width: 100%;">

      <div class="field has-addons is-full" >
        <div class="control is-full" style="width: 100%;">
          <input class="input" type="text" placeholder="Find drugs">
        </div>
        <div class="control">
          <a class="button is-primary">
            Search
          </a>
        </div>
      </div>


    </div>
  </div>
  <div class="panel-block">

  </div>
</section>

