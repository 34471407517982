import { MatSnackBar } from '@angular/material';
import {  FormGroup, FormControl, Validators } from '@angular/forms';
import { InventoryInteractionService } from './../../inventory-interaction.service';
import { Inventory } from './../../inventory.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { mimeType } from './mime-type.validator'

@Component({
  selector: 'app-add-inventory-elements',
  templateUrl: './add-inventory-elements.component.html',
  styleUrls: ['./add-inventory-elements.component.css']
})
export class AddInventoryElementsComponent implements OnInit {
  enteredEmail = "";
  enteredName = "";
  enteredQuantity = "";
  enteredBatchId = "";
  enteredExpireDate = "";
  enteredPrice = "";

  inventory : Inventory ;
  isLoading = false;
  form: FormGroup;
  imagePreview : string;
  private mode = "create";
  private inventoryId : string;


  constructor(private inventoryInteractionService: InventoryInteractionService, public route: ActivatedRoute , private snackBar: MatSnackBar){}

  ngOnInit() {
    this.form = new FormGroup({
      'supplier': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'name': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'salt': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'hsncode': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'mfrs': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'batchid': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'purprice':new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'price': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'mrp': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'quantity': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'pkgquant': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'discount': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'tax': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),      
      'expireDate': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'rack': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'invoice': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'image': new FormControl(null,{validators: [Validators.required],asyncValidators:[mimeType]})

    });
    this.route.paramMap.subscribe((paramMap: ParamMap)=>{
      if (paramMap.has('inventoryId')){
        this.mode = "edit";
        this.inventoryId = paramMap.get('inventoryId');
        this.isLoading = true;
        this.inventoryInteractionService.getInventorys(this.inventoryId).subscribe(inventoryData =>{
        this.isLoading = false;
        this.inventory = {id:inventoryData._id,
          supplier: inventoryData.supplier,
          name: inventoryData.name,
          salt: inventoryData.salt,
          hsncode: inventoryData.hsncode,
          mfrs: inventoryData.mfrs,
          batchid: inventoryData.batchid,
          purprice:inventoryData.purprice,
          price: inventoryData.price,
          mrp: inventoryData.mrp,
          quantity : inventoryData.quantity,
          pkgquant:inventoryData.pkgquant,
          discount:inventoryData.discount,
          tax:inventoryData.tax,                          
          expireDate: inventoryData.expireDate,
          rack: inventoryData.rack,                           
          invoice: inventoryData.invoice,                           
          imagePath: inventoryData.imagePath
        };
        this.form.setValue({'supplier':this.inventory.supplier ,
                            'name':this.inventory.name ,  
                            'salt':this.inventory.salt ,                          
                            'hsncode':this.inventory.hsncode ,
                            'mfrs':this.inventory.mfrs ,
                            'batchid':this.inventory.batchid ,
                            'purprice':this.inventory.purprice,
                            'price':this.inventory.price,
                            'mrp':this.inventory.mrp,
                            'quantity':this.inventory.quantity ,
                            'pkgquant':this.inventory.pkgquant ,
                            'discount':this.inventory.discount ,
                            'tax':this.inventory.tax ,
                            'expireDate':new Date(this.inventory.expireDate),
                            'rack':this.inventory.rack ,
                            'invoice':this.inventory.invoice ,
                            'image':this.inventory.imagePath});
        });
      }else{
        this.mode = "create";
        this.inventoryId = null;
      }
    })
  }
  formatDate(date:Date) {
    return [      
      this.padTo2Digits(date.getMonth() + 1),
      this.padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join('/');
  }
  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  onImagePicked(event: Event){
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({image: file});
    this.form.get('image').updateValueAndValidity();
    console.log(file);
    console.log(this.form);
    const reader = new FileReader();
    reader.onload = ()=>{
      this.imagePreview = reader.result as string;
    };
    reader.readAsDataURL(file);
  }
  onAddInventory() {
    if (this.form.invalid) {
      this.snackBar.open("Please validate your input", "Close",{duration: 10000});
      return;
    }
    if(this.mode === "create"){
      this.inventoryInteractionService.addInventory(this.form.value.supplier,this.form.value.name,this.form.value.salt,
        this.form.value.hsncode,
        this.form.value.mfrs,
        this.form.value.batchid,
        this.form.value.purprice,        
        this.form.value.price,
        this.form.value.mrp,
        this.form.value.quantity,
        this.form.value.pkgquant,
        this.form.value.discount,
        this.form.value.tax,
        this.form.value.expireDate,
        this.form.value.rack,        
        this.form.value.invoice, 
        this.form.value.image
        );
        this.snackBar.open("Drug Added Successfully", "Close",{duration: 10000});
    }else{
      this.inventoryInteractionService.updateInventory(this.inventoryId,this.form.value.supplier,this.form.value.name,this.form.value.salt,
        this.form.value.hsncode,
        this.form.value.mfrs,
        this.form.value.batchid,
        this.form.value.purprice,        
        this.form.value.price,
        this.form.value.mrp,
        this.form.value.quantity,
        this.form.value.pkgquant,
        this.form.value.discount,
        this.form.value.tax,
        this.form.value.expireDate,
        this.form.value.rack,        
        this.form.value.invoice, 
        this.form.value.image);
        this.snackBar.open("Drug Edited Successfully", "Close",{duration: 10000});
    }
    this.form.reset();
  }
}
