<section class="panel" style="margin-left: 10%;margin-right: 10%;">
  <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
    Prediction Chart
  </p>
  <!-- <div class="panel-block  is-center">
    <div  style="height: 613px; width: 900px; margin-left: 70px; " class="is-center">


        <iframe width="1960" height="600" src="https://www.knowi.com/w/zIcyQS66heqQODzTVm88iibNhX8M6In3jgetzR5vCfBUie" frameborder="0" allowfullscreen style="height: 600px;width: 1200px;"></iframe>


    </div>
  </div> -->
  <div class="panel-block">
    <table class="table is-half is-center" style="width:100%">

      <tbody class="is-center">
        <tr class="is-center">


        </tr>

      </tbody>
    </table>

  </div>
</section>

