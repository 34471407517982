<div class="box">
    <!-- <figure class="avatar">
        <img src="https://placehold.it/128x128">
    </figure> -->
    <!-- <a class=" level-item logo-margin" >
      <img src="https://i.ibb.co/L9X6wKM/pharmacare-logo-hori-tagline-2.png" width="152" height="140">
    </a><br> -->

    <form [formGroup]="form" (submit)="onDoctorSignup()" >


      <div class="field">
        <div class="control">

            <input formControlName="name" class="input is-medium" type="text" placeholder="Doctor Name" autofocus=""  >
            <!-- <p *ngIf="registerFormControl.name.errors.required">*Supplier ID number is required</p> -->



        </div>
    </div><br>

    <div class="field">
      <div class="control">

          <input formControlName="contact"  class="input is-medium" type="text" placeholder="Doctor Contact Number" autofocus=""  >

          <span class="error-msg" *ngIf="(registerFormControl.contact.touched ) && registerFormControl.contact.errors?.required">
            *Drugs Available number is required
          </span>
      </div>
    </div><br>


    <div class="field">
      <div class="control">

          <input formControlName="nic" class="input is-medium" type="text" placeholder="Doctor SLMC register number" autofocus=""  >
          <span class="error-msg" *ngIf="(registerFormControl.nic.touched ) && registerFormControl.nic.errors?.required">
            *Drugs Available number is required
          </span>

      </div>
    </div><br>


    <div class="field">
          <div class="control">

            <input formControlName="email" class="input is-medium" type="email" placeholder="Doctor Email" autofocus="" required email>
            <span class="error-msg" *ngIf="(registerFormControl.email.touched ) && registerFormControl.email.errors?.required">
              *Drugs Available number is required
            </span>
          </div>
    </div><br>



    <div class="field">
          <div class="control">

              <input formControlName="password" class="input is-medium" type="password" placeholder="Doctor Password" required>
              <span class="error-msg" *ngIf="(registerFormControl.password.touched ) && registerFormControl.password.errors?.required">
                *Drugs Available number is required
              </span>
          </div>
     </div><br>

     <div class="field">
      <label class="label"></label>
      <div class="file has-name is-full">
        <label class="file-label">
          <button class="file-input" type="button" name="resume" (click)=filePicker.click()></button>
          <input class="file-input" type="file" #filePicker (change)="onImagePicked()">
          <span class="file-cta">
            <span class="file-icon">
              <i class="fa fa-upload"></i>
            </span>
            <span class="file-label" style="color: rgb(162, 162, 162);">
              Choose Profile Picture
            </span>
          </span>
          <span class="file-name is-full" style="width: 900px; color: rgb(162, 162, 162);">
            Image
          </span>
        </label>
      </div>
      <!-- <div class="image-preview" *ngIf=" imagePreview !== '' && imagePreview && form.get('image').valid">
        <img [src]="imagePreview" [alt]="form.value.name">
      </div> -->
    </div><br>




        <button class="button is-block is-primary is-medium is-fullwidth" type="submit">SignUp <i class="fas fas-sign-in" aria-hidden="true"></i></button>
    </form>


</div>
