<section class="panel">
  <!-- <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
    Search Drugs
  </p> -->
  <button  class="button is-primary" style="float: right;right: 410px;margin-top: -57px;" (click)="downloadcsv()">
    DownloadCSV
  </button>
  <input type="file"  class="button is-primary" style="float: right;right:75px;margin-top: -57px;" accept=".csv" (change)="selectcsv($event)"/>
  <input type="submit"  class="button is-primary" style="background-color:red;float: right;margin-top: -57px;" accept=".csv" (click)="uploadcsv()"/>
  <div class="panel-block ">
    <div  style="height: 50px; width: 100%;">

      <div class="field has-addons is-full" >
        <div class="control is-full" style="width: 100%;">
          <input class="input" type="text" placeholder="Find drugs by name" [(ngModel)]="searchTerm">
        </div>
        <div class="control">
          <a class="button is-primary">
            Search
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="panel-block">

  </div>
</section>

<div style="overflow-y:auto ;overflow-x: hidden;height: 500px;">
  <table class="table menu-list" style="width: 100%;" >
    <tbody style="display:table;">
      <div class="spinner-box loadCenter is-center" *ngIf="isLoading">
        <div class="configure-border-1">
          <div class="configure-core"></div>
        </div>
        <div class="configure-border-2">
          <div class="configure-core"></div>
        </div>
      </div>
      <div *ngIf="!isLoading">
        <tr *ngFor="let inventory of inventorys | inventoryFilter: searchTerm">
          <!-- <script>
            document.getElementById("demo").innerHTML =moment.utc(inventory.expireDate).local();
            console.log(moment.utc(inventory.expireDate).local());
          </script> -->
          <div class="div columns btn r">
              <div class="div column" >
                <table class="table" style="width:100%;">
                  <thead style="font-weight: bold;display:table;width:100%">
                    <td style="width:6%;padding:4px;">SUPPLIER</td>
                    <td style="width:11%;padding:4px;">NAME</td>
                    <td style="width:5%;padding:4px;">HSNCODE</td>
                    <td style="width:7%;padding:4px;">MFRS</td>
                    <td style="width:6%;padding:4px;">BATCHID</td>
                    <td style="width:6%;padding:4px;">PURPRI</td>
                    <td style="width:5%;padding:4px;">MRP</td>
                    <td style="width:5%;padding:4px;">PRICE</td>
                    <td style="width:5%;padding:4px;">QUANT</td>
                    <td style="width:7%;padding:4px;">PKGQUAN</td>
                    <td style="width:3%;padding:4px;">DISC</td>
                    <td style="width:3%;padding:4px;">TAX</td>
                    <td style="width:8%;padding:4px;">EXPIRY</td>                    
                    <td style="width:5%;padding:4px;">RACK</td>
                    <td style="width:8%;padding:4px;" ></td>

                  </thead>
                  <tbody style="display:table;width:100%">
                    <td style="width:6%;padding:4px;">{{ inventory.supplier }}</td>
                    <td style="width:11%;padding:4px;">{{ inventory.name }}</td>
                    <td style="width:5%;padding:4px;">{{ inventory.hsncode }}</td>
                    <td style="width:7%;padding:4px;">{{ inventory.mfrs }}</td>
                    <td style="width:6%;padding:4px;">{{ inventory.batchid }}</td>
                    <td style="width:6%;padding:4px;">{{ inventory.purprice }} </td> 
                    <td style="width:5%;padding:4px;">{{ inventory.mrp }} </td>                   
                    <td style="width:5%;padding:4px;">{{ inventory.price }} </td>
                    <td style="width:5%;padding:4px;">{{ inventory.quantity }}</td>                    
                    <td style="width:7%;padding:4px;">{{ inventory.pkgquant }} </td>
                    <td style="width:3%;padding:4px;">{{ inventory.discount }} </td>
                    <td style="width:3%;padding:4px;">{{ inventory.tax }} </td>
                    <td style="width:8%;padding:4px;">{{ inventory.expireDate }} </td>
                    <td style="width:5%;padding:4px;">{{ inventory.rack }}</td>
                    <td style="width:8%;padding:4px;">
                      <a class="button is-small is-primary" style="float:left;font-weight: bold;margin-right: 1%;width:45%;" [routerLink]="['/editi',inventory.id]">Edit </a>
                      <button class="button is-small is-danger" style="font-weight: bold;width:45%" (click)="onDelete(inventory.id)" >Delete</button>
                    </td>

                  </tbody>
                </table>
              </div>
          </div>
          <br>
        </tr>
      </div>
  <br>


<br>


    </tbody>
  </table>
  <textarea name="" id="csvResult" style="visibility:hidden;"></textarea>
  </div>
  <style>

    .menu-list{
      border-radius: 2px;
      width: 220%;
      display: block;
      padding: 10px;
      }
      .btn{
        width: 100%;
        color:hsl(0, 0%, 21%);
        background-color: hsl(0, 0%, 88%);
        border-radius: 3px;
      }
      .newclass :hover{
        background-color: hsl(0, 0%, 21%);
        color: hsl(0, 0%, 88%);
      }

  </style>
