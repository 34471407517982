import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-a-doctor-order-window',
  templateUrl: './a-doctor-order-window.component.html',
  styleUrls: ['./a-doctor-order-window.component.css']
})
export class ADoctorOrderWindowComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
