<div >
<div style="position: fixed; width: 100%; z-index: 1; margin-top: -8.55%; margin-left: -1.44%;">
  <div class="navbar-menu level " style="background-color: hsl(0, 0%, 29%); " aria-label="main navigation;">


  <!-- <app-header-userdetails></app-header-userdetails> -->
  <!-- includes the user profile photo , username , and title of the page -->
<div class="navbar-brand level-left" style="padding-left: 1%;">

  <a class="level-item logo-margin" href="https://bulma.io">
    <img src="https://i.ibb.co/L9X6wKM/pharmacare-logo-hori-tagline-2.png" width="152" height="140">
  </a>

  <div id="navbarBasicExample" class="navbar-menu level-item logo-margin" *ngIf="userIsAuthenticated">

    <div class="navbar-start" style="margin-top: 6px;"  >
      <div style="padding: 4px;margin-right: -10px;">
        <figure class="image is-48x48">
          <img class="is-rounded" src="https://bulma.io/images/placeholders/128x128.png">
        </figure>
      </div>
      <a class="navbar-item subtitle" style="color: azure; margin-left: 10px;margin-bottom: 5px;">
        {{   TrimedDoctors[0]}}
      </a>
    </div>

    <!-- <div class="navbar-start" style="margin-top: 6px;"  >
      <div style="padding: 4px;margin-right: -10px;">
        <figure class="image is-48x48">
          <img class="is-rounded" src="https://bulma.io/images/placeholders/128x128.png">
        </figure>
      </div>
      <a class="navbar-item subtitle" style="color: azure;">
        Cashier
      </a>
    </div> -->

  </div>

</div>


  <!-- <app-header-taskbar></app-header-taskbar> -->
<!-- includes the log out button , search bar , and time -->
<div class="navbar-end level-right" *ngIf="userIsAuthenticated">
  <div class="navbar-item level-item">

    <div class="navbar-item">
      <p class="control has-icons-left">
        <input class="input" type="text" placeholder="Search">
        <span class="icon is-left">
          <i class="fa fa-search" aria-hidden="true"></i>
        </span>
      </p>
    </div>


    <div class="buttons">
      <button class="button is-light" routerLink="/shoppingcart">
        Search
      </button>
      <!-- <button  class="button is-primary"  routerLink="/login">
        <strong>Log in as Admin</strong>
      </button> -->
      <button  class="button is-primary"  (click)="onLogout()">
        <strong>Log out</strong>
      </button>
    </div>


  </div>
</div>

  </div>
  </div>


<div  *ngIf="userIsAuthenticated" style="padding-left: 10%;padding-right: 10%;">

  <script src='http://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js'></script>

  <!-- <div class="navbar">
    <a href="#home">Home</a>
    <a href="#news">News</a>
    <div class="dropdown">
      <button class="dropbtn">Dropdown
        <i class="fa fa-caret-down"></i>
      </button>
      <div class="dropdown-content">
        <div class="container">
    <div class="shopping-cart">
      <div class="shopping-cart-header">
        <i class="fa fa-shopping-cart cart-icon"></i><span class="badge">3</span>
        <div class="shopping-cart-total">
          <span class="lighter-text">Total:</span>
          <span class="main-color-text">$2,229.97</span>
        </div>
      </div>

      <ul class="shopping-cart-items">
        <li class="clearfix">
          <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/cart-item1.jpg" alt="item1" />
          <span class="item-name">Sony DSC-RX100M III</span>
          <span class="item-price">$849.99</span>
          <span class="item-quantity">Quantity: 01</span>
        </li>

        <li class="clearfix">
          <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/cart-item2.jpg" alt="item1" />
          <span class="item-name">KS Automatic Mechanic...</span>
          <span class="item-price">$1,249.99</span>
          <span class="item-quantity">Quantity: 01</span>
        </li>

        <li class="clearfix">
          <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/cart-item3.jpg" alt="item1" />
          <span class="item-name">Kindle, 6" Glare-Free To...</span>
          <span class="item-price">$129.99</span>
          <span class="item-quantity">Quantity: 01</span>
        </li>
      </ul>

      <a href="#" class="button">Checkout</a>
    </div>
  </div>
      </div>
    </div>
  </div> -->


  <section class="panel" >


    <div class="panel-heading level-item" style="background-color:hsl(0, 0%, 33%);color:hsl(0, 0%, 21%) ;height: 90px;">


      <div class="level-left navbar-start"  *ngFor="let user of users">
        <div style="padding: 0px;">
          <figure class="image is-48x48">

            <img class="is-rounded" src="https://bulma.io/images/placeholders/128x128.png">
          </figure>
        </div>
        <a class="navbar-item subtitle" style="color:rgb(170, 170, 170);">
          {{TrimedDoctors[1]}}
        </a>
      </div>

      <div class="level-right navbar-end" >
        <div style="padding: 0px;">
<mat-form-field>
  <mat-label>

    <ul class="navbar-right">
      <li><a href="#" id="cart" style="color: #00d1b2;"><i class="fa fa-shopping-cart fa-cog"></i> Cart <span class="badge"  >{{itemNumber}}</span></a></li>
    </ul>

  </mat-label>
  <mat-select >
    <div class="shopping-cart" style="height: 200%;">
      <mat-option style="padding-top: 40px;padding-bottom: 4px; background-color: rgb(65, 64, 64);margin-top: -8px; border-radius: 10px;">
      <div class="shopping-cart-header">
        <i style="margin-top: 20px;" class="fa fa-shopping-cart cart-icon"></i><span class="badge" style="color: rgb(65, 64, 64) ;">{{itemNumber}}</span>
        <div class="shopping-cart-total" style="margin-bottom: 30px;">
          <span class="lighter-text">Total : </span>
          <span class="main-color-text">Rs. {{total}}</span>
        </div>
      </div> <!--end shopping-cart-header -->
    </mat-option>


      <ul class="shopping-cart-items" >
        <div *ngFor="let entries of itemArray">
        <mat-option style="padding-top: 70px;padding-bottom: 50px;" >
        <li class="clearfix">
          <img class="image is-64x64" [src]="entries[5]" alt="item1" />
          <span class="item-name">{{entries[1]}}</span>
          <span class="item-price">Price : Rs.{{entries[3]}}</span>
          <span class="item-quantity">Quantity : {{entries[4]}}</span>
        </li>
      </mat-option><hr  style="height:2px;border-width:0;color:rgb(208, 205, 205);background-color:rgb(208, 205, 205)">
    </div>

      </ul>
      <form (submit)="onCheckout(pickupForm)" #pickupForm="ngForm">
      <div class="field">
        <label class="label">PICKUP DATE</label>
        <input  type="date" name="pickupDateInput" ngModel #pickupDateInput="ngModel" class="is-center input"   style="width: 100%;" placeholder="071xxxxxxx" />
      </div>

      <button style="width: 100%;"  class="button1" type="submit" >Checkout</button>
    </form>
    </div>

  </mat-select>
</mat-form-field>

        </div>
        <a class="navbar-item subtitle" style="color:black;">

        </a>
      </div>

    </div>






    <div class="panel-block ">
      <div  style="height: 50px; width: 100%;">

        <div class="field has-addons is-full" >
          <div class="control is-full" style="width: 100%;">
            <input class="input" type="text" placeholder="Find drugs by name" [(ngModel)]="searchTerm">
          </div>
          <div class="control">
            <a class="button is-primary">
              Search
            </a>
          </div>
        </div>


      </div>
    </div>
    <div class="panel-block">

    </div>
  </section>

  <div class="columns is-multiline is-mobile has-text-centered" *ngIf="!isLoading" >
        <div class="column is-3-tablet is-6-mobile" *ngFor="let inventory of inventorys | inventoryFilter: searchTerm" >
          <div >
          <div class="card">
            <div class="card-image">
              <figure class="image is-4by3 shadowp">
                <img [src]="inventory.imagePath" [alt]="inventory.name">
              </figure>
            </div>
            <form (submit)="onAddToCart(inventory.id, inventory.name,inventory.expireDate,inventory.price,quantityForm,inventory.imagePath,inventory.quantity )" #quantityForm="ngForm">
            <div class="card-content">
              <header class="card-header">
                <p class="card-header-title">
                  {{ inventory.name }}
                </p>

              </header>

              <div class="card-content ">
                <p class="is-left">{{ inventory.price }}</p>
              </div>
              <div class="card-content ">
                <p class="is-left">Quantity</p>
                <input type="number" class="is-center"  style="width: 50%;" name="quantityNumber" ngModel #quantityNumberInput="ngModel"/>
              </div>
              <footer class="card-footer">

                <button class="button btn is-primary is-rounded is-small-mobile  is-focused " style="margin-left: 50%;" type="submit">Add to Cart</button>

              </footer>
            </div>
            </form>
          </div>

        </div>
      </div>
</div>

<div>
<mat-paginator [length]="totalItems" [pageSize]="itemsPerPage" [pageSizeOptions]="pageSizeOptions" (page)="onChangedPage($event)"></mat-paginator>
</div>

<script async>(function(w, d) { w.CollectId = "5f30ba2eb130972c845e2cca"; var h = d.head || d.getElementsByTagName("head")[0]; var s = d.createElement("script"); s.setAttribute("type", "text/javascript"); s.setAttribute("src", "https://collectcdn.com/launcher.js"); h.appendChild(s); })(window, document);</script>
</div>
</div>
<style>
  .container{
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px,1fr));
	grid-gap: 0.5em;
}

.card{
	height: max-content;
}

.btn {
      font-size:0.875em;
      display:block;
      left:-60px;
      margin-top:35px;
      width:100%;
    }


</style>


