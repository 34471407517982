<div style="margin-left: 24px;"><label><strong style="color:red">Sales for the day : </strong>Rs.&nbsp;</label><label>{{salesamount}}</label></div>
<app-bill-item></app-bill-item>
<!-- <div style="margin-top: 5%;height: 100%;">
  <section class="section columns">
    <div class="column is-full-desktop is-full-mobile">
      <app-add-to-bill></app-add-to-bill>
    </div>
  </section>
  <section class="section columns is-centered" >
    <div class="column is-three-quarters is-full-mobile">
      <app-bill-window></app-bill-window>
    </div>
    <div class="column is-one-quarter is-full-mobile">
      <app-check-out-window></app-check-out-window>
    </div>
  </section>
</div> -->
