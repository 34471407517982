<div class="box">
  <!-- <figure class="avatar">
      <img src="https://placehold.it/128x128">
  </figure> -->
  <!-- <a class=" level-item logo-margin" >
    <img src="https://i.ibb.co/L9X6wKM/pharmacare-logo-hori-tagline-2.png" width="152" height="140">
  </a><br> -->

  <form [formGroup]="form" (submit)="onSignup()" >


    <div class="field">
      <div class="control">

          <input formControlName="name" class="input is-medium" type="name" placeholder="User Name" autofocus="" required >
          <span class="error-msg" *ngIf="(registerFormControl.name.touched ) && registerFormControl.name.errors?.required">
            *Drugs Available number is required
          </span>
      </div>
  </div>

  <div class="card is-left" style="border: black;border-style:initial;border-radius: 10px;">
    <div class="card-body is-left" style="text-align: left;padding: 16px ;" >

        <p class=" is-left" >Role of the user</p><br>

        <mat-radio-group required formControlName="role" class="is-left">
          <mat-radio-button value="pharmacist">Pharmacist</mat-radio-button><br>
          <mat-radio-button value="assistantPharmacist">Assistant Pharmacist</mat-radio-button><br>
          <mat-radio-button value="cashier">Cashier</mat-radio-button><br>
        </mat-radio-group>
        <span class="error-msg" *ngIf="(registerFormControl.role.touched ) && registerFormControl.role.errors?.required">
          *Drugs Available number is required
        </span>
    </div>
  </div>

  <div class="field">
    <div class="control"><br>

        <input formControlName="contact" class="input is-medium" type="contact" placeholder="User Contact Number" autofocus="" required >
        <span class="error-msg" *ngIf="(registerFormControl.contact.touched ) && registerFormControl.contact.errors?.required">
          *Drugs Available number is required
        </span>

    </div>
  </div>


  <div class="field">
    <div class="control">

        <input formControlName="nic" class="input is-medium" type="nic" placeholder="User NIC" autofocus="" required >
        <span class="error-msg" *ngIf="(registerFormControl.nic.touched ) && registerFormControl.nic.errors?.required">
          *Drugs Available number is required
        </span>
    </div>
  </div>


  <div class="field">
        <div class="control">

          <input formControlName="email" class="input is-medium" type="email" placeholder="User Email"  autofocus="" required email>
          <span class="error-msg" *ngIf="(registerFormControl.email.touched ) && registerFormControl.email.errors?.required">
            *Drugs Available number is required
          </span>
        </div>
  </div>



  <div class="field">
        <div class="control">

            <input formControlName="password" class="input is-medium" type="password" placeholder="User Password" required>
            <span class="error-msg" *ngIf="(registerFormControl.password.touched ) && registerFormControl.password.errors?.required">
              *Drugs Available number is required
            </span>
        </div>
   </div>



<br>

      <button  class="button is-block is-primary is-medium is-fullwidth" type="submit">SignUp User</button>
  </form>


</div>
