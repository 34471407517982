import { Inventory } from './inventory.model';

import { Pipe ,PipeTransform} from '@angular/core';
@Pipe({name: 'inventoryFilter',pure: true})
export class InventoryFilterPipe implements PipeTransform{

  transform(inventorys: Inventory[], searchTerm: string) :Inventory[] {
    if(!inventorys || !searchTerm){
      return inventorys;
    }
    searchTerm = searchTerm.toLocaleLowerCase();
    
     return inventorys.filter( inventory =>
      // calculateMatchPercentage(inventory.name,searchTerm)>60 || calculateMatchPercentage(inventory.salt,searchTerm)>60
      // || 
      inventory.name.toLocaleLowerCase().indexOf(searchTerm) !== -1 || inventory.salt.toLocaleLowerCase().indexOf(searchTerm) !== -1);      
      //inventory.name.toLocaleLowerCase().includes(searchTerm));
      
  }
}
function calculateMatchPercentage(str1: string, str2: string) {
  if (!str1 || !str2) {
      return 0.0;
  }

  // Convert strings to lowercase for case-insensitive comparison
  str1 = str1.toLowerCase();
  str2 = str2.toLowerCase();

  const commonChars = new Set([...str1].filter(char => str2.includes(char)));
  const matchPercentage = (commonChars.size / str2.length) * 100;

  return matchPercentage;
}

