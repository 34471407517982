<!-- <div style="margin-top: 5%;height: 870px;">

  <section class="section columns is-left">
    <div class="column is-one-quarter is-full-mobile">
      <app-sales-chart></app-sales-chart>
    </div>
    <div class="column is-three-quarters is-full-mobile" style="width: 680px;">
      <app-sales-report></app-sales-report>
    </div>
  </section>
</div> -->

<div >
  <div>
    <div style="height:900px">
      <section class="section columns" style="padding:0px !important;padding-top:15px !important;">
        <div class="column is-full-desktop is-full-mobile">
          <!-- <app-search-supplier-window></app-search-supplier-window> -->
          <div class="tabs is-toggle is-fullwidth">
            <ul>
              <li class="is-active ">
                <a routerLink="/salesreport">
                  <!-- <span class="icon is-small"><i class="fas fa-music" aria-hidden="true"></i></span> -->
                  <span>Sales Chart</span>
                </a>
              </li>

              <li>
                <a routerLink="/salesreport/report">
                  <!-- <span class="icon is-small"><i class="fas fa-music" aria-hidden="true"></i></span> -->
                  <span>Sales Report</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    <section class="panel" style="margin: 3px; width: 99%;">

      <div >
        <div  style="height: 610px; width: 100%;">

          <app-sales-chart></app-sales-chart>
        </div>
      </div>
      <div class="panel-block">

      </div>
    </section>
    </div>

    </div>
</div>

