import { AuthService } from '../auth.service';
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBarModule, MatSnackBar } from '@angular/material';

@Component({
   templateUrl: './forgotpassword.component.html',
   styleUrls: ['./forgotpassword.component.css']
})
export class ForgotPasswordComponent {

  constructor(public authService : AuthService, private sankBar : MatSnackBar){}

  onReset(form:  NgForm){
    if(form.invalid){
      this.sankBar.open("Please enter valid data", 'Close',{duration: 10000});
      return;

    }
    this.sankBar.open("Logging... Please wait ", 'Close',{duration: 10000});    
    
    this.authService.updatepassword(form.value.email,form.value.password);
  };

};
