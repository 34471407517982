<div style="height: 100%;">  
  <section class="section columns">
    <div class="column is-three-quarters is-full-mobile">
    <!-- <app-add-to-bill></app-add-to-bill> -->
    <section class="panel">
        <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
          Search Drugs
        </p>
        <div class="panel-block ">
          <div  style="height: 40px; width: 100%;">

            <div class="field has-addons is-full" >
              <div class="control is-full" style="width: 100%;">
                <input class="input" type="text" placeholder="Find drugs" [(ngModel)]="searchTerm" (ngModelChange)="searchKey($event)" ng-model-options="{debounce: 1000}" autofocus>
              </div>
              <!-- <div class="control">
                <a class="button is-primary">
                  Search
                </a>
              </div> -->
            </div>
            <!-- <div style="width: 100%;height:100px;overflow: auto;" *ngFor="let inventory of inventorylist">
              <div style="width: 100%;">
              {{inventory.name}}</div>
            </div> -->
            <ul id="druglist" style="margin-top: -12px;font-size:14px;overflow-y: auto;max-height: 250px;border:1px solid #ededed;">
              <!-- results of ngFor is passed to appFilter with argument searchText -->
              <li *ngFor="let inventory of inventorylist" (click)="populatecolosel(inventory.id)" style="cursor: pointer;padding-left: 10px;border:1px solid #ededed;">
                {{inventory.name}}##{{inventory.quantity}}##{{inventory.expireDate}}
              </li>
            </ul>
          </div>
        </div>
        <div class="slides-wrapper" id="carousel" ng2-carouselamos style="height: 280px;visibility:hidden;"
        [items]="items"
        [width]="250"
        [$prev]="prev"
        [$next]="next"
        [$item]="item"
        ></div>
      <ng-template #prev>
        <!-- <i class="fas fa-chevron-circle-left fa-3x" ></i> -->
      </ng-template>

      <ng-template #next>
        <!-- <i class="fas fa-chevron-circle-right fa-3x"></i> -->
      </ng-template>

      <ng-template #item >
        <div  style="height:275px;font-size:12px;overflow-y: auto;" *ngFor="let inventory of inventorys">
          <div class="column is-3-tablet is-6-mobile" style="width: 250px;" >
            <div >
            <div class="card" style="height:260px;">
              <form (submit)="onAddToBill(inventory.id, inventory.name,inventory.hsncode,inventory.mfrs,inventory.batchid,inventory.mrp,inventory.price,inventory.tax,inventory.expireDate,quantityForm,inventory.quantity,inventory.purprice)" #quantityForm="ngForm">
              <div class="card-content" >                               
                <span class="is-left" style="font-weight:bold;font-size:10px;">{{ inventory.name }}</span><br>
                <span class="is-left" style="width:200px">Pack MRP : Rs. {{ inventory.mrp*inventory.pkgquant }}</span><br>
                <span class="is-left" style="width:200px">MRP : Rs. {{ inventory.mrp }}</span><br>
                <span class="is-left" style="width:200px">Price : Rs.{{ inventory.price }}</span><br>
                <span class="is-left" style="width:200px">Qty : {{ inventory.quantity }}</span><br>                  
                <span class="is-left" style="width:200px">Rack : {{ inventory.rack }}</span><br>
                <span class="is-left" style="width:200px">Salt : {{ inventory.salt }}</span><br>
                <span class="is-left" style="width:200px">Expiry : {{ inventory.expireDate }}</span><br>
                <span class="is-left" style="width:200px">Batch : {{ inventory.batchid }}</span><br>
                <input type="number" class="is-center"  style="width: 60%;margin-left: 22%;" name="quantityNumber" ngModel #quantityNumberInput="ngModel"/><br><br>
                <footer class="card-footer">
                  <button class="button btn is-primary is-rounded is-small-mobile  is-focused " style="left: 0px;margin-top: 10px;">Add to Bill</button>
                </footer>
              </div>
            </form>
            </div>


           </div>
         </div>
        </div>
      </ng-template>
    </section>

    </div>
    <div class="column is-one-quarter is-full-mobile">
      <div style="margin-top: -40px;margin-left:-80px;height:40px;"><label><strong style="color:red">Print Invoice : </strong></label>
        <select class="select" style="font-size: 12px;width:75px;margin-top:-4px;" name="invoice" placeholder="Select Invoice" (change)="changeprintinvoice($event);">  
          <option selected >Select Invoice</option>                    
          <option *ngFor="let invoice of invoices" value="{{invoice}}">{{invoice}}</option>  
      </select>
      <button id="invoiceprint" (click)="printinvoice()" class="button btn is-primary is-rounded is-small-mobile  is-focused " style="visibility:hidden;margin-top: -6px;padding: 0px;width: 60px;float:right;">Print</button> 
      </div>

      <!-- <app-check-out-window></app-check-out-window> -->

  <section class="panel">
        <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
          Patient Info
        </p>
      <form (submit)="onCustomerAddition(customerForm)" #customerForm="ngForm">
        <div class="panel-block ">
          <div  style="height: 300px; width: 100%;">
            <br>
            <div class="field has-addons" style="margin-bottom: 20px;">
              <div class="control" style="margin-right: 2%;">
                <label class="label" style="font-size: 14px;">Bill Number : </label>
              </div>
              <div class="control">
                <label class="label" style="font-size: 12px;font-weight:normal;padding-top: 3px;">{{billNumber}}</label>
              </div>
            </div>  
            <div class="field has-addons" style="margin-top: 20px;margin-bottom: 20px;">
              <div class="control" style="margin-right: 5%;padding-top: 3%;">
                <label class="label" style="font-size: 14px;">Dr. Name : <span style="color: red;">&#42;</span></label>
              </div>
              <div class="control">
                <input class="input" type="text" style="font-size: 12px;width:125px;" placeholder="Enter Doctor Name" required name="doctorName" ngModel #doctorNameInput="ngModel">
              </div>
            </div>
            <div class="field has-addons" style="margin-top: 20px;margin-bottom: 20px;">
              <div class="control" style="margin-right: 4%;padding-top: 3%;">
                <label class="label" style="font-size: 14px;">Patient Id : <span style="font-size: 14px;color: red;">&#42;</span></label>
              </div>
              <div class="control">
                <input class="input" type="number" style="font-size: 12px;width:125px;" placeholder="Enter Patient Id" required name="patientId" ngModel #patientIdInput="ngModel" (change)="fillpatientname($event.target.value,customerForm)">
              </div>
            </div>
            <div class="field has-addons" style="margin-top: 20px;margin-bottom: 20px;">
              <div class="control" style="margin-right: 1%;padding-top: 3%;">
                <label class="label" style="font-size: 14px;">Patient Name  : <span style="color: red;">&#42;</span></label>
              </div>
              <div class="control">
                <input class="input" type="text" style="font-size: 12px;width:105px;" placeholder="Enter Patient Name" required name="patientName" ngModel #patientNameInput="ngModel">
              </div>
            </div>     
            <div class="field has-addons" style="margin-top: 20px;margin-bottom: 20px;">
              <div class="control" style="margin-right: 1%;padding-top: 2%;">
                <label class="label" style="font-size: 14px;">Payment Type : <span style="color: red;">&#42;</span></label>
              </div>
              <div class="control">
                <!-- <input class="input" type="text" placeholder="Enter Payment Type" name="paymenttype" ngModel #taxInput="ngModel"> -->
                <select class="select" style="font-size: 12px;width:80px;" name="paymenttype" placeholder="Payment Type" ngModel #taxInput="ngModel">  
                  <!-- <option disabled>Select Method</option>   -->                    
                  <option *ngFor="let payment of paymentType">{{payment}}</option>  
              </select>  
              </div>              
            </div><footer class="card-footer">
              <button class="button btn is-primary is-rounded is-small-mobile  is-focused " style="visibility:hidden;" type="submit">Add</button>
            </footer>                   
          </div>          
        </div>        
      </form>
    </section>
    </div>
  </section>


  <section class="section columns" style="margin-top: -4%;">
    <div class="column is-three-quarters is-full-mobile">
      <!-- <app-bill-window></app-bill-window> -->

      <section class="panel">
        <form (submit)="onAddToCheckout(itemArray,billForm)" #billForm="ngForm">
        <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
          Checkout Items
        </p>
        <div class="panel-block ">
          <div  style="height: 300px; width: 100%;">

            <div style="overflow-y:auto ;overflow-x: hidden;height: 300px;">
              <table class="table" style="font-size: 12px;width:100%">
                <thead >
                  <tr>
                    <th>QUANTITY</th>
                    <th>NAME</th>
                    <th>HSNCODE</th>
                    <th>MFRS</th>
                    <th>BATCHID</th>
                    <th>EXPIRY</th>
                    <th>PRICE</th>                    
                    <th>CGST%</th>
                    <th>SGST%</th>
                    <th>TAXABLE</th>                      
                    <th>TOTAL</th>                                                           
                    <th style="width:40px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let entries of itemArray">
                    <td >{{entries[10]}}</td>
                    <td >{{entries[1]}}</td>
                    <td >{{entries[2]}}</td>
                    <td >{{entries[3]}}</td>
                    <td >{{entries[4]}}</td>
                    <td >{{entries[5]}}</td>
                    <td >{{entries[6]}}</td>
                    <td >{{entries[7]}}</td>
                    <td >{{entries[8]}}</td>
                    <td >{{entries[9]}}</td> 
                    <td >{{entries[11]}}</td>                   
                    <td><button class="button is-small is-danger" style="font-weight: bold;padding:0px;" (click)="onRemovefromBill(entries,null)">Del</button></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
        <div class="panel-block">
          <button class="button is-primary   is-fullwidth"  type="submit" >
            Check Out
          </button>
        </div>
      </form>
      </section>


    </div>
    <div class="column is-one-quarter is-full-mobile">


      <!-- <app-check-out-window></app-check-out-window> -->

  <section class="panel">
        <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
          Bill Detail
        </p>
      <form #printForm="ngForm"><!-- (onchange)="onPrintBill(total,printForm,dataArray)" -->
        <div class="panel-block ">
          <div style="height: 300px; width: 100%;">
            <br>
            <div class="field has-addons" style="margin-bottom: 22px;">
              <div class="control" style="margin-right: 23%;padding-top: 3%;">
                <label class="label" style="font-size: 14px;">Total<span style="color: red;">&#42;</span> : </label>
              </div>
              <div class="control" style="padding-top: 3%;">
                <label class="label" style="font-size: 12px;">{{getfixed2(total)}}</label>
              </div>
            </div>
            <div class="field has-addons" style="margin-top: 20px;margin-bottom: 20px;">
              <div class="control" style="margin-right: 27%;padding-top: 3%;">
                <label class="label" style="font-size: 14px;">Tax<span style="color: red;">&#42;</span> : </label>
              </div>
              <div class="control">
                <label class="label" style="font-size: 12px;">{{getfixed2(tax) | number:'1.2-2'}}</label>
              </div>
            </div>            
            <div class="field has-addons" style="margin-top: 20px;margin-bottom: 20px;">
              <div class="control" style="margin-right: 1%;padding-top: 3%;">
                <label class="label" style="font-size: 14px;">Paid Amount<span style="color: red;">&#42;</span> :</label>
              </div>
              <div class="control">
                <input class="input" (change)="paymentcalculation(printForm)" style="font-size: 12px;width:105px" type="text" placeholder="0" required name="paidAmount" ngModel #paidAmountInput="ngModel">
              </div>
            </div>
            <div class="field has-addons" style="margin-top: 20px;margin-bottom: 20px;">
              <div class="control" style="margin-right: 18%;padding-top: 3%;">
                <label class="label" style="font-size: 14px;">Balance :</label>
              </div>
              <div class="control" style="padding-top: 3%;">
                <label class="label" style="font-size: 12px;">{{getfixed2(balance)}}  </label>
              </div>
            </div>
            <div class="field has-addons" style="margin-top: 20px;margin-bottom: 20px;">
              <div class="control" style="margin-right: 18%;padding-top: 3%;">
                <label class="label" style="font-size: 14px;">Savings :</label>
              </div>
              <div class="control" style="padding-top: 3%;">
                <label class="label" style="font-size: 12px;">{{getfixed2(savings)}}  </label>
              </div>
            </div>
          </div>
        </div>        
      </form>
      <div class="panel-block">
        <button id="paybtn" class="button is-primary is-fullwidth" style="width: 35%;background-color:red" (click)="onPrintBill(total,printForm,customerForm)" >
          Pay Bill
        </button>
        <button class="button is-primary is-fullwidth" style="width: 35%;margin-left: 30%;" (click)="print()">
          Print Bill
        </button>
      </div>
    </section>

    </div>
  </section>
</div>

<div id="print-section" style="visibility:hidden;">
  <div  style="width: 100%;">
    <table style="width:100%;line-height:12px;">
      <tr>
      	<td style="width:6.9%"></td>
        <td style="width:27.65%">{{billNumber}}</td>
        <td style="width:28.62%"></td>
        <td style="width:6.9%"></td>
        <td style="width:29.93%">Dodhpur, Aligarh</td>
      </tr>
      <tr>
      	<td style="width:6.9%"></td>
        <td style="width:27.65%">@INVDATE@</td>
        <td style="width:28.62%"></td>
        <td style="width:6.9%"></td>
        <td style="width:29.93%">Shop No 1, D N Tower</td>
      </tr>
      <tr>
      	<td style="width:6.9%"></td>
        <td style="width:27.65%">@PHARMASIST@</td>
        <td style="width:28.62%"></td>
        <td style="width:6.9%"></td>
        <td style="width:29.93%">7895636360</td>
      </tr>
      <tr>
      	<td style="width:6.9%"></td>
        <td style="width:27.65%">@PNAME@</td>
        <td style="width:28.62%"></td>
        <td style="width:6.9%"></td>        
        <td style="width:29.93%">UP8120B001858</td>
      </tr>
      <tr>
      	<td style="width:6.9%"></td>
        <td style="width:27.65%">@PCONTACT@</td>
        <td style="width:28.62%"></td>
        <td style="width:6.9%"></td>
        <td style="width:29.93%">09AAIFO5735C1Z8</td>
      </tr>
      <tr>
      	<td style="width:6.9%"></td>
        <td style="width:27.65%">@DOCTOR@</td>
        <td style="width:28.62%"></td>
        <td style="width:6.9%"></td>
        <td style="width:29.93%">22723704000061</td>
      </tr>
    </table>
    <br>
    <table style="width:100%;line-height: 10px;margin-top:2px;">
      <tr *ngFor="let entries of itemArray">
      	<td style="width:5.9%">{{entries[10]}}</td>
        <td style="width:19.91%">{{getsubstr(entries[1],22)}}</td>
        <td style="width:8.77%">{{entries[2]}}</td>
        <td style="width:6%">{{getsubstr(entries[3],5)}}</td>
        <td style="width:9%">{{entries[4]}}</td>
        <td style="width:8%">{{entries[5]}}</td>
        <td style="width:5%">N</td>
        <td style="width:7.5%">{{getfixed2(entries[12])}}</td>
        <td style="width:7.5%">{{getfixed2(entries[7])}}</td>
        <td style="width:7.5%">{{getfixed2(entries[8])}}</td>
        <td style="width:9%">{{getfixed2(entries[14])}}</td>
        <td style="width:10%">{{getfixed2(entries[13])}}</td>
      </tr>      
    </table>
    <table style="width:100%;position:absolute;bottom:0%">
      <tr><td style="width:92%"></td><td style="width:10%">{{getfixed2(printMRP)}}</td></tr>
      <tr><td style="width:92%">Payment done by @PAYTYPE@</td><td style="width:10%">{{getfixed2(savings)}}</td></tr>
      <tr><td style="width:92%">Goods once sold will not be taken back.</td><td style="width:10%">{{getfixed2(total)}}</td></tr>     
    </table>    
    </div> 
</div>
<div id="print-invoice" style="visibility:hidden;">
  <div  style="width: 100%;" *ngFor="let inv of invoicecontent">
    <table style="width:100%;line-height:12px;">
      <tr>
      	<td style="width:6.9%"></td>
        <td style="width:27.65%">{{inv["invoice"]}}</td>
        <td style="width:28.62%"></td>
        <td style="width:6.9%"></td>
        <td style="width:29.93%">Dodhpur, Aligarh</td>
      </tr>
      <tr>
      	<td style="width:6.9%"></td>
        <td style="width:27.65%">{{getlocaldate(inv["dateTime"])}}</td>
        <td style="width:28.62%"></td>
        <td style="width:6.9%"></td>
        <td style="width:29.93%">Shop No 1, D N Tower</td>
      </tr>
      <tr>
      	<td style="width:6.9%"></td>
        <td style="width:27.65%">{{inv["user"]}}</td>
        <td style="width:28.62%"></td>
        <td style="width:6.9%"></td>
        <td style="width:29.93%">7895636360</td>
      </tr>
      <tr>
      	<td style="width:6.9%"></td>
        <td style="width:27.65%">{{inv["patientInfo"]["pname"]}}</td>
        <td style="width:28.62%"></td>
        <td style="width:6.9%"></td>        
        <td style="width:29.93%">UP8120B001858</td>
      </tr>
      <tr>
      	<td style="width:6.9%"></td>
        <td style="width:27.65%">{{inv["patientInfo"]["pid"]}}</td>
        <td style="width:28.62%"></td>
        <td style="width:6.9%"></td>
        <td style="width:29.93%">09AAIFO5735C1Z8</td>
      </tr>
      <tr>
      	<td style="width:6.9%"></td>
        <td style="width:27.65%">{{inv["patientInfo"]["dname"]}}</td>
        <td style="width:28.62%"></td>
        <td style="width:6.9%"></td>
        <td style="width:29.93%">22723704000061</td>
      </tr>
    </table>
    <br>
    <table style="width:100%;line-height: 10px;margin-top:2px;">
      <tr *ngFor="let entries of printinvcontent">
      	<td style="width:5.9%">{{entries[10]}}</td>
        <td style="width:19.91%">{{getsubstr(entries[1],22)}}</td>
        <td style="width:8.77%">{{entries[2]}}</td>
        <td style="width:6%">{{getsubstr(entries[3],5)}}</td>
        <td style="width:9%">{{entries[4]}}</td>
        <td style="width:8%">{{entries[5]}}</td>
        <td style="width:5%">N</td>
        <td style="width:7.5%">{{getfixed2(entries[12])}}</td>
        <td style="width:7.5%">{{getfixed2(entries[7])}}</td>
        <td style="width:7.5%">{{getfixed2(entries[8])}}</td>
        <td style="width:9%">{{getfixed2(entries[14])}}</td>
        <td style="width:10%">{{getfixed2(entries[13])}}</td>
      </tr>      
    </table>
    <table style="width:100%;position:absolute;bottom:0%">
      <tr><td style="width:92%"></td><td style="width:10%">{{getfixed2(invprintMRP)}}</td></tr>
      <tr><td style="width:92%">Payment done by {{inv["patientInfo"]["type"]}}</td><td style="width:10%">{{getfixed2(invsavings)}}</td></tr>
      <tr><td style="width:92%">Goods once sold will not be taken back.</td><td style="width:10%">{{getfixed2(invtotal)}}</td></tr>     
    </table>    
    </div> 
</div>
<script src="../../../../../../node_modules/flickity/dist/flickity.pkgd.min.js"></script>
  <style>

    .menu-list{
      border-radius: 2px;
      width: 220%;
      display: block;
      padding: 10px;
      }
      .btn{
        width: 100%;
        color:hsl(0, 0%, 21%);
        background-color: hsl(0, 0%, 88%);
        border-radius: 3px;
      }
      .newclass :hover{
        background-color: hsl(0, 0%, 21%);
        color: hsl(0, 0%, 88%);
      }



      /* external css: flickity.css */

*{
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body { font-family: sans-serif; }

.gallery {
  background: #EEE;
}

.gallery-cell {
  width: 66%;
  height: 200px;
  margin-right: 10px;
  background: #8C8;
  counter-increment:gallery-cell;
}

/* cell number */
.gallery-cell:before {
  display: block;
  text-align: center;
  content: counter(gallery-cell);
  line-height: 200px;
  font-size: 80px;
  color: white;
}

  </style>




<script
  src="https://code.jquery.com/jquery-3.5.1.js"
  integrity="sha256-QWo7LDvxbWT2tbbQ97B53yJnYU3WhH/C8ycbRAkjPDc="
  crossorigin="anonymous"></script>

