import { MatSnackBar } from '@angular/material';
import { SalesInformationArray } from './../../salesInformationArray.model';
import { NgForm } from '@angular/forms';
import { InventoryInteractionService } from './../../../a-inventory-window/inventory-interaction.service';
import { Subscription } from 'rxjs';
import { Inventory } from './../../../a-inventory-window/inventory.model';
import { Component, OnInit } from '@angular/core';
import { SalesInteractionService } from './../../sales-interaction.service';
import { AuthService } from './../../../../auth/auth.service';
import { Number } from 'mongoose';

@Component({
  selector: 'app-bill-item',
  templateUrl: './bill-item.component.html',
  styleUrls: ['./bill-item.component.css']
})
export class BillItemComponent implements OnInit {
  array: Array<SalesInformationArray> =[];
  items: Array<any> =[];
  arr: Array<any> =[];
  arr1: Array<any> =[];
  itemArray: Array<any> =[];
  searchTerm: string;
  inventorys: Inventory[] = [];
  inventorys_org: Inventory[] = [];  
  inven: Inventory[] = [];
  newArray: Array<any> = [];
  num: string;
  total: number=0;
  printMRP:number=0;
  tax: number;
  paidAmount: number;
  balance: number=0;  
  billNumber:string;
  patientInfo:any;
  patients:any;
  patientname:string='';
  isPaid= false;
  isLoading= false;
  counter:string;
  paymentType:Array<any> = ['','Cash','UPI','Credit Card']
  private inventorySubs: Subscription;
  savings: number=0;
  invoiceDate:string;
  arrmonths = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
  inventorylist:Array<any> =[];
  salesbill:[];
  invoices:string[];
  selectedinvoiceno:string;
  printinvcontent:{};
  invoicecontent:any;
  invtotal: number=0;
  invprintMRP:number=0;
  invsavings: number=0;

  constructor(private inventoryInteractionService: InventoryInteractionService, private salesInteractionService:SalesInteractionService , private snackbar : MatSnackBar,private authService:AuthService ) {
    this.items =[
      {name: 'https://i.ibb.co/L9X6wKM/pharmacare-logo-hori-tagline-2.png'},
    ]
   }

  ngOnInit() {
    if(!localStorage.getItem("username"))
    {
      this.authService.logout();
    }
    this.isLoading = true;
    this.patientInfo = {"billno":"","dname":"","pid":"","pname":"","type":""};
    this.inventoryInteractionService.getInventory(null,null);    
    this.inventorySubs = this.inventoryInteractionService.getInventoryUpdateListener()
      .subscribe((posts: Inventory[]) => {
        this.isLoading = false;
        this.inventorys_org = posts.sort();
        this.inventorylist = posts.sort();
      });
    this.billNumber = "OS-TEMP-280723";
    this.patientInfo.billno = this.billNumber;
    this.inventoryInteractionService.getPatients()
      .subscribe(result =>
        {
          this.patients = result.patients.sort();
        });
    var user = "";
    if(localStorage.getItem("userRole")=="admin")
    {
      user = "admin";
    }
    else
    {
      user = localStorage.getItem("username")
    }
    this.salesInteractionService.getsalesinvoices(user).subscribe((data)=>
    {
        this.salesbill = data.invoices;        
        var  dataArray = this.salesbill.reduce((res, item) => {
          res.push([item["invoice"]]);
          return res;
      }, []);
      this.invoices = dataArray;
    });
  }

  ngOnDestroy() {
    this.inventorySubs.unsubscribe();
  }

  onAddToBill(itemId:string, name:string, hsncode:string, mfrs:string, batchid:string, MRP:string, price:string, tax:string, expireDate:string , form:NgForm,quantity:string,purprice:string)
  {
    if(form != null)
    {
      var sum = this.itemArray.filter(element=> element[0]===itemId).reduce((accumulator, currentValue) => {
        return accumulator + currentValue[10]
      },0);
      
      if(parseInt(form.value.quantityNumber)> parseInt(quantity) || (parseInt(form.value.quantityNumber)+sum) > parseInt(quantity))
      {
        this.snackbar.open("Cannot add quantity greater than existing quantity!! Please review your Checkout Items list!!!", 'Close',{duration: 10000});
        return;
      }
      else{
          var tempprice:any = price;
          var totalprice:any = tempprice*form.value.quantityNumber;
          var tempMRP:any = MRP;
          var totalMRP:any = tempMRP*form.value.quantityNumber;
          var temptax:any = tax;
          var taxableamount = ((totalprice*100)/(parseFloat(temptax)+100)).toFixed(2);
          var printtax:any = ((totalMRP*100)/(parseFloat(temptax)+100)).toFixed(2);
          var expdate = new Date(expireDate);
          var expdatestring = this.arrmonths[expdate.getMonth()]+"-"+expdate.getFullYear().toString().substring(2);
          this.itemArray.push([itemId,name,hsncode,mfrs,batchid,expdatestring,price,(temptax/2).toFixed(2),(temptax/2).toFixed(2),taxableamount,form.value.quantityNumber,totalprice,MRP,totalMRP,printtax,purprice]);      
      }
    }
    //  console.log(this.itemArray);
  }

  onRemovefromBill(removearray:Array<any> =[],form:NgForm)
  {
    var tarray:Array<any> = [];
    tarray.push([removearray[0],removearray[1],removearray[10]]);
    var itemindex = this.itemArray.indexOf(removearray);    
    this.itemArray.splice(itemindex,1);    
    if(this.itemArray.length==0)
    {
      this.tax= 0;
      this.total =0;
      this.balance = 0;
      this.savings=0;
    }
    //  console.log(this.itemArray);
  }

  onAddToCheckout(checkoutArray: Array<any> =[], form: NgForm){
    let length = checkoutArray.length;    
    this.total = 0;
    this.tax = 0;
    this.savings = 0;
    this.printMRP = 0;

    for (let count = 0 ; count < length; count++) {
      this.total = this.total + checkoutArray[count][11];
      this.tax = this.tax + checkoutArray[count][11]-checkoutArray[count][9];
      this.savings = this.savings + (checkoutArray[count][12]*checkoutArray[count][10]-checkoutArray[count][11]);
      this.savings = roundTo(this.savings,2);
      this.printMRP=this.printMRP+checkoutArray[count][13];      
    }
    this.balance = this.total;

    console.log(this.total);
    return this.total;

  }
  

  onCustomerAddition(form:NgForm)
  {
    this.patientInfo.dname = form.value.doctorName;
    this.patientInfo.pid = form.value.patientiId;
    this.patientInfo.pname = form.value.patientName;    
    console.log(form.value);
  }
  fillpatientname(val:string,custform:NgForm)
  {
    var patient = this.patients.filter((element) => {      
      return (element.pid == val); 
    });
    if(patient.length>0)    
    custform.controls["patientName"].setValue(patient[0].name);
    else
    custform.controls["patientName"].setValue('');

  }
  paymentcalculation(form: NgForm)
  {
    if(form.value.paidAmount)
    {
      this.balance = this.total - form.value.paidAmount;      
    }

  }

  onPrintBill(total: number,form: NgForm,formcust:NgForm){    
    //this.array = ['nnkn','kdjfh'];
    var currentdate = new Date();
    this.invoiceDate = currentdate.getFullYear().toString()+"-"+this.arrmonths[currentdate.getMonth()]+"-"+currentdate.getDate().toString()+" "+currentdate.getHours().toString()+":"+currentdate.getMinutes().toString()+":"+currentdate.getSeconds().toString();    
          
    this.patientInfo.dname = formcust.value.doctorName;
    this.patientInfo.pid = formcust.value.patientId;
    this.patientInfo.pname = formcust.value.patientName;
    this.patientInfo.type = formcust.value.paymenttype;   

    this.paidAmount = form.value.paidAmount;
    if(!formcust.value.doctorName || !formcust.value.patientId || !formcust.value.patientName || !formcust.value.paymenttype)    
    {
      this.snackbar.open("Required Values in patient Info is not provided !!", 'Close',{duration: 10000});
      return;
    }

    if(isNaN(this.balance) || isNaN(total) || isNaN(this.paidAmount) || this.paidAmount==0)
    {
      this.snackbar.open("Paid Amount in Check-Out not provided !!", 'Close',{duration: 10000});
      return;
    }    
    var  dataArray = this.itemArray.reduce((res, item) => {
      res.push([item[0], item[1],item[10],item[6],item[9],item[11],item[15]]);
      return res;
  }, []);
  
    this.salesInteractionService.getinvoicenumber().subscribe((data)=>{
      this.billNumber = data.counter; 
      this.salesInteractionService.addSales(dataArray,
        this.total,
        this.tax,
        this.paidAmount,
        this.balance,
        this.patientInfo,
        this.billNumber
        );     
           document.getElementById("paybtn").style.display = "none";
        this.snackbar.open("Transaction Added to Sales Report !!", 'Close',{duration: 7000});     
    });          
  }
  getsubstr(val1:string,val2:number)
  {
    return val1.substring(0,val2);
  }
  getfixed2(val:any)
  {
    var tempval = parseFloat(val);
    return tempval.toFixed(2);
  }
  getlocaldate(val:any)
  {
    var tempval = new Date(val);
    return tempval.toLocaleString();
  }  

  searchKey(data: string) {
    this.searchTerm = data.toLowerCase();    
    this.search();
  }

  search() {
    this.inventorys = [];
    document.getElementById("druglist").style.visibility="visible";
    document.getElementById("carousel").style.visibility="hidden";
    if(!this.inventorylist || !this.searchTerm){
      this.inventorylist = this.inventorys_org;
      return;
    }
    this.inventorylist = this.inventorys_org.filter((element) => {      
     return (element.name.toLowerCase().indexOf(this.searchTerm) !== -1 || element.salt.toLowerCase().indexOf(this.searchTerm) !== -1); 
     //(calculateMatchPercentage(element.name,this.searchTerm)>60 || calculateMatchPercentage(element.salt,this.searchTerm)>60
     //  || element.name.toLowerCase().indexOf(this.searchTerm) !== -1 || element.salt.toLowerCase().indexOf(this.searchTerm) !== -1);      
    });
  }
  populatecolosel(val:string)
  {
    document.getElementById("druglist").style.visibility="hidden";
    document.getElementById("carousel").style.visibility="visible";
    this.inventorys = this.inventorys_org.filter((element) => {      
      return element.id === val;
    });
  }
  changeprintinvoice(value:any)
  {
        this.selectedinvoiceno = value.target.value;
        let selectedinvoice = this.salesbill.filter((element) => {      
          return (element["invoice"] == value.target.value);                
         });
         if(selectedinvoice.length>0)
         {
          document.getElementById("invoiceprint").style.visibility = "visible";
          var billitems = [];
          billitems = selectedinvoice[0]["drugName"];        
          this.invoicecontent = selectedinvoice;
          this.printinvcontent = this.innerJoinAB(this.inventorys_org,billitems);
         }
         else
         {
          document.getElementById("invoiceprint").style.visibility = "hidden";
         }         
  }
  innerJoinAB(a,b) {
    let data = [];
    var invtotaltemp = 0;
    var invsavingstemp = 0;
    var invprintMRPtemp = 0;
    var temparrmoths = this.arrmonths;

    a.forEach(function(obj, index) {
        // Search through objects in first loop
        b.forEach(function(obj2,i2){
        // Find objects in 2nd loop
        // if obj1 is present in obj2 then push to result.
        if(obj2[0]== obj.id)
          {
            let temptax = parseFloat(obj.tax);
            var tempMRP:any = parseFloat(obj.mrp);
            var totalMRP:any = tempMRP*parseInt(obj2[2]);
            var printtax:any = ((totalMRP*100)/(temptax+100)).toFixed(2);
            invtotaltemp = invtotaltemp + obj2[5];
            invsavingstemp = invsavingstemp + (totalMRP-obj2[5]);
            invsavingstemp = roundTo(invsavingstemp,2);
            invprintMRPtemp = invprintMRPtemp+totalMRP;
            var expdate = new Date(obj.expireDate);
            var expdatestring = temparrmoths[expdate.getMonth()]+"-"+expdate.getFullYear().toString().substring(2);
            data.push([obj.id,obj.name,obj.hsncode,obj.mfrs,obj.batchid,expdatestring,obj.price,(temptax/2).toFixed(2),(temptax/2).toFixed(2),obj2[4],obj2[2],obj2[5],tempMRP.toFixed(2),totalMRP,printtax,-1]);
          }
        });
    });
    this.invtotal = invtotaltemp;
    this.invsavings = invsavingstemp;
    this.invprintMRP = invprintMRPtemp;
    return data;
}
  printinvoice(): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-invoice').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          body:{font-family: sans-serif;}
          td{font-size:12px;}
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }


  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    printContents = printContents.replace("@PNAME@",this.patientInfo.pname).replace("@INVDATE@",this.invoiceDate)
    .replace("@DOCTOR@",this.patientInfo.dname).replace("@PCONTACT@",this.patientInfo.pid).replace("@PAYTYPE@",this.patientInfo.type)
    .replace("@PHARMASIST@",localStorage.getItem("username"));
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          body:{font-family: sans-serif;}
          td{font-size:12px;}
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

}
function roundTo(number, digits) {
  if (digits === undefined) {
    digits = 0;
  }

  var multiplicator = Math.pow(10, digits);
  number = parseFloat((number * multiplicator).toFixed(11));
  var test =(Math.round(number) / multiplicator);
  return +(test.toFixed(digits));
}
function calculateMatchPercentage(str1: string, str2: string) {
  if (!str1 || !str2) {
      return 0.0;
  }

  // Convert strings to lowercase for case-insensitive comparison
  str1 = str1.toLowerCase();
  str2 = str2.toLowerCase();

  const commonChars = new Set([...str1].filter(char => str2.includes(char)));
  const matchPercentage = (commonChars.size / str2.length) * 100;

  return matchPercentage;
}