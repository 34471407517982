<div style="overflow:auto ;height: 500px;">
  <table class="table is-full menu-list"  style="width:100%">
     <div *ngFor="let inventor of inventoryis" class="is-full">
              <div class="div column btn">
                <table class="table is-full" style="width: 100%;">
                  <thead style="font-weight: bold;">
                    <td>SUPPLIER</td>
                    <td>DRUGNAME</td>
                    <td>HSBCODE</td>
                    <td>MFRS</td>
                    <td>BATCHID</td>
                    <td>PUR.PRICE</td>
                    <td>MRP</td>
                    <td>PRICE</td>
                    <td>QUANTITY</td>
                    <td>PKG.QUANTITY</td>
                    <td>DISCOUNT</td>
                    <td>TAX%</td>
                    <td>EXPIRY</td>                    
                    <td>RACK</td>
                    <td><a class="button is-small is-danger" style="font-weight: bold;" >{{ calcRemainingDays(inventor.expireDate)}} days remaining </a> </td>
                    <td ></td>

                  </thead>
                  <tbody>
                    <td>{{ inventor.supplier }}</td>
                    <td>{{ inventor.name }}</td>
                    <td>{{ inventor.hsncode }}</td>
                    <td>{{ inventor.mfrs }}</td>
                    <td>{{ inventor.batchid }}</td>
                    <td>{{ inventor.purprice }} </td>                    
                    <td>{{ inventor.mrp }} </td> 
                    <td>{{ inventor.price }} </td>
                    <td>{{ inventor.quantity }}</td>                    
                    <td>{{ inventor.pkgquant }} </td>
                    <td>{{ inventor.discount }} </td>
                    <td>{{ inventor.tax }} </td>
                    <td>{{ inventor.expireDate }} </td>
                    <td>{{ inventor.rack }}</td>
                    <td class="level-center" ><a class="button is-small is-primary" style="font-weight: bold;" (click)="OpenMessageBox(inventor.supplier,inventor.name,inventor.quantity,inventor.batchId,inventor.expireDate,inventor.price  )">Request</a></td>
                  </tbody>
                </table>
              </div>
              <br>
            </div>        
        
  </table>
  </div>
  <style>

    .menu-list{
      border-radius: 2px;
      width: 220%;
      display: block;
      padding: 10px;
      }
      .btn{
        width: 100%;
        color:hsl(0, 0%, 21%);
        background-color: hsl(0, 0%, 88%);
        border-radius: 3px;
      }
      .newclass :hover{
        background-color: hsl(0, 0%, 21%);
        color: hsl(0, 0%, 88%);
      }

  </style>


