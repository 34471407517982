<section class="panel">
  <!-- <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
    Search Drugs
  </p> -->
  <button  class="button is-primary" style="float: right;" (click)="downloadcsv()">
    DownloadCSV
  </button>
  <!-- <div class="panel-block ">
    <div  style="height: 50px; width: 100%;">

      <div class="field has-addons is-full" >
        <div class="control is-full" style="width: 100%;">
          <input class="input" type="text" placeholder="Find expenses by detail" [(ngModel)]="searchTerm">
        </div>
        <div class="control">
          <a class="button is-primary">
            Search
          </a>
        </div>
      </div>


    </div>
  </div> -->
  </section>

<div style="overflow-y:auto ;overflow-x: hidden;height: 500px;">
  <table class="table menu-list" style="width: 100%;" >
    <tbody style="display:table;width:100% !important;">
      <div class="spinner-box loadCenter is-center" *ngIf="isLoading">
        <div class="configure-border-1">
          <div class="configure-core"></div>
        </div>
        <div class="configure-border-2">
          <div class="configure-core"></div>
        </div>
      </div>

      <div *ngIf="!isLoading" style="display:table;width:100% !important">
        <tr *ngFor="let expense of expenses" style="display:table;width:100% !important">
          <!-- <script>
            document.getElementById("demo").innerHTML =moment.utc(inventory.expireDate).local();
            console.log(moment.utc(inventory.expireDate).local());
          </script> -->
          <div class="div columns btn r" style="width:100% !important;">
              <div class="div column"  style="width:100% !important;">
                <table class="table"  style="width:100% !important;">
                  <thead style="font-weight: bold;display:table;width:100% !important;">
                    <td style="width:22%">DETAIL</td>
                    <td style="width:22%">AMOUNT</td>
                    <td style="width:22%">EXPENSE DATE</td>
                    <td style="width:22%">BILL NUMBER</td>
                    <td style="width:12%"></td>

                  </thead>
                  <tbody style="display:table;width:100% !important;">
                    <td style="width:22%">{{ expense.detail }}</td>
                    <td style="width:22%">{{ expense.amount }}</td>
                    <td style="width:22%">{{ expense.expdate }}</td>
                    <td style="width:22%">{{ expense.billno }}</td>
                    <td style="width:12% !important;" class="">
                      <a class="button is-small is-primary" style="float:left;font-weight: bold;margin-right: 1%;" [routerLink]="['/edite',expense.id]">Edit </a>
                      <button class="button is-small is-danger" style="font-weight: bold;" (click)="onDelete(expense.id)" >Delete</button>
                    </td>

                  </tbody>
                </table>
              </div>
          </div>
          <br>
        </tr>
      </div>
  <br>


<br>


    </tbody>
  </table>
  </div>
  <style>

    .menu-list{
      border-radius: 2px;
      width: 220%;
      display: block;
      padding: 10px;
      }
      .btn{
        width: 100%;
        color:hsl(0, 0%, 21%);
        background-color: hsl(0, 0%, 88%);
        border-radius: 3px;
      }
      .newclass :hover{
        background-color: hsl(0, 0%, 21%);
        color: hsl(0, 0%, 88%);
      }

  </style>
