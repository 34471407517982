<section class="panel">
  <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
    Expired Drugs
  </p>
  <div class="panel-block ">
    <div  style="height: 500px; width: 100%;">

      <app-expired-items></app-expired-items>
    </div>
  </div>
  <div class="panel-block">

  </div>
</section>






