import { from } from 'rxjs';
import { SalesInteractionService } from './../a-pointofsale-window/sales-interaction.service';
import { Component, OnInit } from '@angular/core';

import * as tf from '@tensorflow/tfjs';

@Component({
  selector: 'app-a-prediction-report-window',
  templateUrl: './a-prediction-report-window.component.html',
  styleUrls: ['./a-prediction-report-window.component.css']
})
export class APredictionReportWindowComponent implements OnInit {


  constructor( ) { }

  ngOnInit() {



  }





}
