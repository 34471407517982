
<div style="margin-top: 5%;">


  <div style="margin-top: 2%; height:900px">
    <section class="section columns">
      <div class="column is-full-desktop is-full-mobile">
        <!-- <app-search-supplier-window></app-search-supplier-window> -->
        <div class="tabs is-toggle is-fullwidth">
          <ul>
            <li >
              <a routerLink="/settings">
                <!-- <span class="icon is-small"><i class="fas fa-music" aria-hidden="true"></i></span> -->
                <span>Add New Accounts</span>
              </a>
            </li>
            <li class="is-active ">
              <a routerLink="/settings/DoctorAccount">
                <!-- <span class="icon is-small"><i class="fas fa-image" aria-hidden="true"></i></span> -->
                <span>Manage Doctor Account</span>
              </a>
            </li>
            <li >
              <a routerLink="/settings/APharmasistAccounts">
                <!-- <span class="icon is-small"><i class="fas fa-music" aria-hidden="true"></i></span> -->
                <span>Manage Pharmacy User Account</span>
              </a>
            </li>
            <li>
              <a routerLink="/settings/CashierAccounts">
                <!-- <span class="icon is-small"><i class="fas fa-music" aria-hidden="true"></i></span> -->
                <span>View User Statictics</span>
              </a>
            </li>


          </ul>
        </div>
      </div>
    </section>
  <section class="panel is-left is-half" style="margin: 3px;margin-top: -30px; width:98%;">
    <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
      Doctor Users
    </p>
    <div class="panel-block ">
      <div  style="height: 610px; width: 115%;">




        <div style="overflow-y:auto ;overflow-x: hidden;height: 500px;">
          <table class="table is-full menu-list"  >
            <tbody>

              <div class="spinner-box loadCenter is-center" >
                <div class="configure-border-1">
                  <div class="configure-core"></div>
                </div>
                <div class="configure-border-2">
                  <div class="configure-core"></div>
                </div>
              </div>
                <div >
                <tr  *ngFor="let doctor of doctors" >
                  <div class="div columns  is-full btn r" style="width: 135%;" >
                      <div class="div column" >
                        <table class="table is-full" style="width: 100%;">
                          <thead style="font-weight: bold;">
                            <td>Name</td>
                            <td>Email </td>
                            <td >Doctor Id</td>
                            <td >Contact</td>
                            <td *ngIf="password" >Password</td>
                            <td ></td>

                          </thead>
                          <tbody>
                            <td style="width: 13%;">{{doctor.name}}</td>
                            <td style="width: 23%;">{{doctor.email}} </td>
                            <td style="width: 10%;">{{doctor.docId}}</td>
                            <td style="width: 7%;">{{doctor.contact}}</td>
                            <td style="width: 10%;" *ngIf="password">{{doctor.password}}</td>
                            <td class="level-right" >
                              <a class="button is-small is-primary" style="font-weight: bold;margin-right: 1%;" [routerLink]="['/update',doctor.id]">Edit </a>
                      <button class="button is-small is-danger" style="font-weight: bold;" (click)="onDelete(doctor.id)" >Delete</button>
                            </td>

                          </tbody>
                        </table>
                      </div>
                  </div><br>
                </tr>
              </div>





            </tbody>
          </table>
          </div>






      </div>
    </div>
    <div class="panel-block">

    </div>
  </section>

  </div>

  </div>
  <style>

    .menu-list{
      border-radius: 2px;
      width: 220%;
      display: block;
      padding: 10px;
      }
      .btn{
        width: 100%;
        color:hsl(0, 0%, 21%);
        background-color: hsl(0, 0%, 88%);
        border-radius: 3px;
      }
      .newclass :hover{
        background-color: hsl(0, 0%, 21%);
        color: hsl(0, 0%, 88%);
      }

  </style>
