<div style="margin-top: 5%;height: 870px;">

  <section class="section columns is-centered">
    <div class="column is-full is-full-mobile" >
      <app-chart-window></app-chart-window>
    </div>
    <!-- <div class="column is-half is-full-mobile">
      <app-prediction-chart-window></app-prediction-chart-window>
    </div> -->
  </section>
</div>
