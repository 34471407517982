import { MatSnackBar } from '@angular/material';
import { element } from 'protractor';
import { AuthService } from 'src/app/auth/auth.service';
import { SalesInteractionService } from './../../../a-pointofsale-window/sales-interaction.service';
import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Sales } from 'src/app/mainwindow/a-pointofsale-window/sales.model';
import * as html2pdf from 'html2pdf.js';
import { saveAs } from 'file-saver';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-sales-report-items',
  templateUrl: './sales-report-items.component.html',
  styleUrls: ['./sales-report-items.component.css']
})
export class SalesReportItemsComponent implements OnInit {

  searchTerm : string;
  sales: Sales[] = [];
  isLoading= false;
  userIsAuthenticated = false;
   salesSubs: Subscription;
   authStatusSub: Subscription;
   isHidden: boolean = true;

  constructor(private salesInteractionService: SalesInteractionService, private authService: AuthService , private snackBar : MatSnackBar){}

  ngOnInit() {
    this.isLoading = true;
    this.salesInteractionService.getSales();
    this.salesSubs = this.salesInteractionService.getSalesUpdateListener()
      .subscribe((posts: Sales[]) => {
        this.isLoading = false;
        this.sales = posts;
      });

    this.userIsAuthenticated = this.authService.getIsAuth();

    this.authStatusSub = this.authService.getAuthStatusListener()
    .subscribe(isAuthenticated =>{
      this.userIsAuthenticated = isAuthenticated;
    });
  }

  downloard(){
    const options ={
      name: 'output.pdf',
      image: {type: 'jpeg'},
      html2canvas:{},
      jsPDF: {orientation: 'portrait'},
      pagebreak: { mode: 'avoid-all', before: '#page2el' }
    }
    const element:Element = document.getElementById('table')

    this.snackBar.open("Sales Report Downloarding..... ", "Close",{duration: 10000});
    html2pdf()
            .from(element)
            .set(options)
            .save()
  }
  downloadcsv(rangeform:NgForm)
  {
    var buttonName = document.activeElement.getAttribute("Name");
    if(buttonName=="amount")
    {
      this.downloadsalesamountcsv(rangeform);
    }
    else if(buttonName=="inventory")
    {
      this.downloadsalesinventorycsv(rangeform);
    }
  }
  downloadsalesamountcsv(rangeform:NgForm) {
    var salesfiltered;
    var startdate = new Date(rangeform.value.startDate);
    var enddate = new Date(rangeform.value.endDate);
    enddate.setDate(enddate.getDate()+1);
    if(!startdate || !enddate)
    {
      salesfiltered = this.sales;
    }
    else
    {
      salesfiltered = this.sales.filter((element) => {      
        return (new Date(element.dateTime)>(startdate) && new Date(element.dateTime)<(enddate));
      }); 
    }
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(this.sales[0]);
    let csv = salesfiltered.map(row => 
      header.map(fieldName => 
        JSON.stringify(fieldName == "drugName"?concatenatejson(row[fieldName]):row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], {type: 'text/csv' })
    saveAs(blob, "myFile.csv");
}
downloadsalesinventorycsv(rangeform:NgForm) {
  var salesfiltered;
  var startdate = new Date(rangeform.value.startDate);
  var enddate = new Date(rangeform.value.endDate);
  enddate.setDate(enddate.getDate()+1);
  if(!startdate || !enddate)
  {
    salesfiltered = this.sales;
  }
  else
  {
    salesfiltered = this.sales.filter((element) => {      
      return (new Date(element.dateTime)>(startdate) && new Date(element.dateTime)<(enddate));
    }); 
  }
  let csvcontent = "BillNumber,BillAmount,BilledBy,BilledOn,ItemId,ItemName,Quantity,PurchasePrice,Rate,TaxableAmount,Total"+"\r\n";
  for(var i=0;i<salesfiltered.length;i++)
  {
    var csvrow = '';
    var element= salesfiltered[i].drugName;
    for(var j=0;j<element.length;j++)
    {
      var inelement = element[j];
      csvrow += salesfiltered[i].invoice+","+salesfiltered[i].paidAmount+","+salesfiltered[i].user+","+salesfiltered[i].dateTime+","+(inelement[0]?inelement[0]:'')+","+(inelement[1]?inelement[1].replace(/,/g," "):'')+","+(inelement[2]?inelement[2]:'')+","+(inelement[6]?inelement[6]:'')+","+(inelement[3]?inelement[3]:'')+","+(inelement[4]?inelement[4]:'')+","+(inelement[5]?inelement[5]:'')+"\r\n";
    }
    csvcontent += csvrow;      
  }  

  var blob = new Blob([csvcontent], {type: 'text/csv' })
  saveAs(blob, "myFile.csv");
}
}
function concatenatejson(val)
{
  var data = '';
  var tmparr =[];
  val.forEach(element => {
    element.forEach(inelement=>{
      data=data+inelement;
      data = data+"#";
    });
    data = data+"@@";
  });
  return data.replace(",","#");  
}
