<section class="panel">
  <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
    New Orders
  </p>
  <div class="panel-block ">
    <div  style="height: 500px; width: 100%;">

      <app-new-doctor-order-item></app-new-doctor-order-item>

    </div>
  </div>
  <div class="panel-block">

  </div>
</section>
