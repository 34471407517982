<div style="height:900px">
  <section class="section columns" style="padding-bottom:2px;padding-top:15px;">
    <div class="column is-full-desktop is-full-mobile">
      <!-- <app-search-supplier-window></app-search-supplier-window> -->
      <div class="tabs is-toggle is-fullwidth">
        <ul>
          <li class="is-active1 ">
            <a routerLink="/inventory/create">
              <!-- <span class="icon is-small"><i class="fas fa-image" aria-hidden="true"></i></span> -->
              <span>Inventory Form</span>
            </a>
          </li>
          <li class="is-active ">
            <a routerLink="/inventory/">
              <!-- <span class="icon is-small"><i class="fas fa-music" aria-hidden="true"></i></span> -->
              <span>Inventory List</span>
            </a>
          </li>

        </ul>
      </div>
    </div>
  </section>

  <!-- <section class="section columns" style="margin: 0;margin-top: -7%;">
    <div class="column is-full-desktop is-full-mobile">
      <app-search-inventory></app-search-inventory>
    </div>
  </section> -->

<section class="panel" style="margin: 1%;">

  <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
    Drug Inventory
  </p>
  <div class="panel-block ">
    <div  style="height: 590px; width: 100%;">

      <app-drug-inventory-items></app-drug-inventory-items>
    </div>
  </div>
  <div class="panel-block">

  </div>
</section>
</div>
