<div style="margin-top: 5%; padding-right:3%; padding-left:3%;">
  <div class="columns is-offset-1 is-11 is-centered ">
    <app-stat-panel></app-stat-panel>
  </div>
  <div>
    <section class="section columns">
      <div class="column is-one-fourth-desktop is-full-mobile">
        <app-expiredate-window></app-expiredate-window>
      </div>
      <div class="is-two-third-desktop column is-full-mobile">
        <app-saleschart-window></app-saleschart-window>
      </div>
      <div class="is-one-fourth-desktop column is-full-mobile">
        <app-outofstock-window></app-outofstock-window>
      </div>
    </section>
    <section class="section columns ">
      <div class="column is-left is-one-third-desktop is-full-mobile" style="margin-top: -28%;">
        <app-predictionchart-window></app-predictionchart-window>
      </div>
      <div class="column is-one-third-desktop is-full-mobile" style="margin-top: -6%;">
        <app-salesdetails-window></app-salesdetails-window>
      </div>
      <div class="column is-one-third-desktop is-full-mobile" style="margin-top: -28%;">
        <app-about-to-outof-stock-window></app-about-to-outof-stock-window>
      </div>
    </section>
  </div>

  <!-- testing new unrouted components -->

  <!-- Doctor Order Window -->
  <!-- <div>
    <section class="section columns">
      <div class="is-full column is-full-mobile">
        <app-a-doctor-order-window></app-a-doctor-order-window>
      </div>
    </section>
  </div> -->

  <!-- Point of Sale Window -->
  <!-- <div>
    <section class="section columns">
      <div class="is-full column is-full-mobile">
        <app-a-pointofsale-window></app-a-pointofsale-window>
      </div>
    </section>
  </div> -->


  <!-- Supplier Window -->
  <!-- <div>
    <section class="section columns">
      <div class="is-full column is-full-mobile">
        <app-a-suppliers-window></app-a-suppliers-window>
      </div>
    </section>
  </div> -->

  <!-- Inventory Window -->
  <!-- <div>
    <section class="section columns">
      <div class="is-full column is-full-mobile">
        <app-a-inventory-window></app-a-inventory-window>
      </div>
    </section>
  </div> -->

  <!-- Prediction report window -->
  <!-- <div>
    <section class="section columns">
      <div class="is-full column is-full-mobile">
        <app-a-prediction-report-window></app-a-prediction-report-window>
      </div>
    </section>
  </div> -->


<!-- Sales Report Window -->
  <!-- <div>
    <section class="section columns">
      <div class="is-full column is-full-mobile">
        <app-a-sales-window></app-a-sales-window>
      </div>
    </section>
  </div> -->


  <!-- Expire date Window -->
  <!-- <div>
    <section class="section columns">
      <div class="is-full column is-full-mobile">
        <app-a-exp-outofstock-window></app-a-exp-outofstock-window>
      </div>
    </section>
  </div> -->

</div>
