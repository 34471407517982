import { MatSnackBar } from '@angular/material';
import { InventoryInteractionService } from './../../inventory-interaction.service';
import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Inventory } from '../../inventory.model';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-drug-inventory-items',
  templateUrl: './drug-inventory-items.component.html',
  styleUrls: ['./drug-inventory-items.component.css']
})
export class DrugInventoryItemsComponent implements OnInit {
  searchTerm : string;
  inventorys : Inventory[] = [];
  isLoading= false;
  private inventorySubs: Subscription;

  constructor(private inventoryInteractionService: InventoryInteractionService, private snackBar :MatSnackBar) { }

  ngOnInit() {
    this.isLoading = true;
    this.inventoryInteractionService.getInventory(null,null);
    this.inventorySubs = this.inventoryInteractionService.getInventoryUpdateListener()
      .subscribe((posts: Inventory[]) => {
        this.isLoading = false;
        this.inventorys = posts;
      });
  }
  onDelete(supplierId: string) {
    this.inventoryInteractionService.deleteInventory(supplierId);
    this.snackBar.open("Drug Deleted Successfully", "Close",{duration: 10000});
  }
  ngOnDestroy() {
    this.inventorySubs.unsubscribe();
  }
  downloadcsv() {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(this.inventorys[0]);
    let csv = this.inventorys.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer).replace(",","#")).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], {type: 'text/csv' })
    saveAs(blob, "myFile.csv");
}
selectcsv(event: Event)
{
  const file = (event.target as HTMLInputElement).files[0];
  const textArea = document.querySelector("#csvResult");
  const reader = new FileReader();  
  if(file && file.type == "text/csv")
  {
    console.log("here");
    reader.onload = function (e){      
      const csvArray = csvToArr(e.target.result, ",");
      textArea.innerHTML = JSON.stringify(csvArray, null, 4);

      
    };
    reader.readAsText(file);
  }
  else
  {
    alert("Only CSV files could be uploaded.");
  }
}
uploadcsv()
{
  const textArea = document.querySelector("#csvResult");
  var data = JSON.parse("{\"inventorys\":"+textArea.innerHTML+"}");
  
  
  this.inventoryInteractionService.uploadinventory(data).subscribe(response=>{        
        this.snackBar.open("CSV Uploaded Successfully.", "Close",{duration: 10000});
            });
      
}

}
function csvToArr(stringVal, splitter) {
  const [keys, ...rest] = stringVal
    .trim()
    .split("\r\n")
    .map((item) => item.split(splitter));

  const formedArr = rest.map((item) => {
    const object = {};
    keys.forEach((key, index) => (object[key] = item.at(index)));
    return object;
  });
  return formedArr;
}