<div style="overflow-y:auto ;overflow-x: hidden;height: 500px;">
  <table class="table is-full menu-list"  >
    <tbody>
      <div class="" >
        <tr>
          <div class="div columns  is-full btn " style="width: 595px;" >
              <div class="div column" >
                <table class="table is-full" style="width: 561px;">
                  <thead style="font-weight: bold;">
                    <td>Name</td>
                    <td>Drugs </td>
                    <td >Order ID </td>
                    <td >Pickup Date </td>
                    <td >Order Date </td>
                  </thead>
                  <tbody>
                    <td>dkjfh</td>
                    <td>Drsldflksugs </td>
                    <td >Order sdlfkjsID </td>
                    <td >Pickupslkdfj Date </td>
                    <td >Ordersdlkfj Date </td>
                  </tbody>
                </table>
              </div>
              <div>
            </div>
          </div>
        </tr>
        <tr>

        </tr>
      </div>
  <br>
  <div class="" >
    <tr>
      <div class="div columns  is-full btn " style="width: 595px;" >
          <div class="div column" >
            <table class="table is-full" style="width: 561px;">
              <thead style="font-weight: bold;">
                <td>Name</td>
                <td>Drugs </td>
                <td >Order ID </td>
                <td >Pickup Date </td>
                <td >Order Date </td>
              </thead>
              <tbody>
                <td>dkjfh</td>
                <td>Drsldflksugs </td>
                <td >Order sdlfkjsID </td>
                <td >Pickupslkdfj Date </td>
                <td >Ordersdlkfj Date </td>
              </tbody>
            </table>
          </div>
          <div>
        </div>
      </div>
    </tr>
    <tr>

    </tr>
  </div>
<br>
<div class="" >
  <tr>
    <div class="div columns  is-full btn " style="width: 595px;" >
        <div class="div column" >
          <table class="table is-full" style="width: 561px;">
            <thead style="font-weight: bold;">
              <td>Name</td>
              <td>Drugs </td>
              <td >Order ID </td>
              <td >Pickup Date </td>
              <td >Order Date </td>
            </thead>
            <tbody>
              <td>dkjfh</td>
              <td>Drsldflksugs </td>
              <td >Order sdlfkjsID </td>
              <td >Pickupslkdfj Date </td>
              <td >Ordersdlkfj Date </td>
            </tbody>
          </table>
        </div>
        <div>
      </div>
    </div>
  </tr>
  <tr>

  </tr>
</div>
<br>
<div class="" >
  <tr>
    <div class="div columns  is-full btn " style="width: 595px;" >
        <div class="div column" >
          <table class="table is-full" style="width: 561px;">
            <thead style="font-weight: bold;">
              <td>Name</td>
              <td>Drugs </td>
              <td >Order ID </td>
              <td >Pickup Date </td>
              <td >Order Date </td>
            </thead>
            <tbody>
              <td>dkjfh</td>
              <td>Drsldflksugs </td>
              <td >Order sdlfkjsID </td>
              <td >Pickupslkdfj Date </td>
              <td >Ordersdlkfj Date </td>
            </tbody>
          </table>
        </div>
        <div>
      </div>
    </div>
  </tr>
  <tr>

  </tr>
</div>
<br>
<div class="" >
  <tr>
    <div class="div columns  is-full btn " style="width: 595px;" >
        <div class="div column" >
          <table class="table is-full" style="width: 561px;">
            <thead style="font-weight: bold;">
              <td>Name</td>
              <td>Drugs </td>
              <td >Order ID </td>
              <td >Pickup Date </td>
              <td >Order Date </td>
            </thead>
            <tbody>
              <td>dkjfh</td>
              <td>Drsldflksugs </td>
              <td >Order sdlfkjsID </td>
              <td >Pickupslkdfj Date </td>
              <td >Ordersdlkfj Date </td>
            </tbody>
          </table>
        </div>
        <div>
      </div>
    </div>
  </tr>
  <tr>

  </tr>
</div>
<br>
<div class="" >
  <tr>
    <div class="div columns  is-full btn " style="width: 595px;" >
        <div class="div column" >
          <table class="table is-full" style="width: 561px;">
            <thead style="font-weight: bold;">
              <td>Name</td>
              <td>Drugs </td>
              <td >Order ID </td>
              <td >Pickup Date </td>
              <td >Order Date </td>
            </thead>
            <tbody>
              <td>dkjfh</td>
              <td>Drsldflksugs </td>
              <td >Order sdlfkjsID </td>
              <td >Pickupslkdfj Date </td>
              <td >Ordersdlkfj Date </td>
            </tbody>
          </table>
        </div>
        <div>
      </div>
    </div>
  </tr>
  <tr>

  </tr>
</div>
<br>
<div class="" >
  <tr>
    <div class="div columns  is-full btn " style="width: 595px;" >
        <div class="div column" >
          <table class="table is-full" style="width: 561px;">
            <thead style="font-weight: bold;">
              <td>Name</td>
              <td>Drugs </td>
              <td >Order ID </td>
              <td >Pickup Date </td>
              <td >Order Date </td>
            </thead>
            <tbody>
              <td>dkjfh</td>
              <td>Drsldflksugs </td>
              <td >Order sdlfkjsID </td>
              <td >Pickupslkdfj Date </td>
              <td >Ordersdlkfj Date </td>
            </tbody>
          </table>
        </div>
        <div>
      </div>
    </div>
  </tr>
  <tr>

  </tr>
</div>
<br>


    </tbody>
  </table>
  </div>
  <style>

    .menu-list{
      border-radius: 2px;
      width: 220%;
      display: block;
      padding: 10px;
      }
      .btn{
        width: 100%;
        color:hsl(0, 0%, 21%);
        background-color: hsl(0, 0%, 88%);
        border-radius: 3px;
      }
      .newclass :hover{
        background-color: hsl(0, 0%, 21%);
        color: hsl(0, 0%, 88%);
      }

  </style>
