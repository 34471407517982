<div style="overflow-y:auto ;overflow-x: hidden;height: 667px;">

  <div class="spinner-box loadCenter" *ngIf="isLoading">
    <div class="configure-border-1">
      <div class="configure-core"></div>
    </div>
    <div class="configure-border-2">
      <div class="configure-core"></div>
    </div>
  </div>


  <form [formGroup]="form" (submit)="onAddExpense()"  *ngIf="!isLoading">

    <div class="fieldl">
      <label class="label" >Details</label>
      <input type="text" class="is-center input" #detailInput  formControlName="detail"  style="width: 100%;" placeholder="detail"  >
      <p class="login-error" *ngIf="!detailInput.valid && detailInput.touched">Please enter some text</p>
    </div>

    <div class="fieldr">
      <label class="label" >AMOUNT</label>
      <input type="text" class="is-center input" #amountInput  formControlName="amount"  style="width: 100%;" placeholder="123"  >
      <p class="login-error" *ngIf="!amountInput.valid && amountInput.touched">Please enter amount</p>
    </div>
    <div class="fieldl">
      <label class="label">EXPENSE DATE</label>
      <input  type="date" class="is-center input" #expdateInput formControlName="expdate"  style="width: 100%;" placeholder="071xxxxxxx" />
      <p class="login-error" *ngIf="!expdateInput.valid && expdateInput.touched">Please enter valid expense date</p>
    </div>
    <div class="fieldr">
      <label class="label">BILL NUMBER</label>
      <input  type="text" class="is-center input" #billnoInput formControlName="billno"  style="width: 100%;" placeholder="T123" />
      <p class="login-error" *ngIf="!billnoInput.valid && billnoInput.touched">Please enter any bill number</p>
    </div>       
    <div class="panel-block" style="width: 100%;padding-top: 20px;padding-bottom: 20px;border: 1px solid #dbdbdb !important;">
      <button class="button is-primary is-fullwidth" type="submit">
        Add
      </button>
    </div>
  </form>

  <!-- <table class="table is-full" style="width:100%">
    <thead>
      <tr>
        <th class="is-center">DRUG ID</th>
        <th>NAME</th>
        <th>QUANTITY</th>
        <th>BATCH ID</th>
        <th>EXPIRE DATE</th>
        <th>DRUG IMAGE</th>
      </tr>
    </thead>
    <tbody>
      <tr>

        <td><input type="text" class="is-center input" id="text"  style="width: 100%;" placeholder="19283838" /></td>
        <td><input type="text" class="is-center input" id="text"  style="width: 100%;" placeholder="Panedol" /></td>
        <td><input type="text" class="is-center input" id="text"  style="width: 100%;" placeholder="15 units" /></td>
        <td><input type="text" class="is-center input" id="text"  style="width: 100%;" placeholder="B872178" /></td>
        <td><input type="text" class="is-center input" id="text"  style="width: 100%;" placeholder="12/03/2020" /></td>
        <td>
          <div class="file has-name is-right">
          <label class="file-label">
            <input class="file-input" type="file" name="resume">
            <span class="file-cta">
              <span class="file-icon">
                <i class="fa fa-upload"></i>
              </span>
              <span class="file-label">
                Choose Image
              </span>
            </span>
            <span class="file-name">
              Screen Shot
            </span>
          </label>
        </div>
      </td>
      </tr>

    </tbody>
  </table> -->



  </div>

  <script src="https://raw.githack.com/Wikiki/bulma-calendar/master/dist/js/bulma-calendar.min.js"></script>
  <script>
    var options = {
      dateFormat: "DD.MM.YYYY",
      isRange: true
    };
    bulmaCalendar.attach('[type="date"]', options);
  </script>
  <style>

    .menu-list{
      border-radius: 2px;
      width: 220%;
      display: block;
      padding: 10px;
      }
      .btn{
        width: 100%;
        color:hsl(0, 0%, 21%);
        background-color: hsl(0, 0%, 88%);
        border-radius: 3px;
      }
      .newclass :hover{
        background-color: hsl(0, 0%, 21%);
        color: hsl(0, 0%, 88%);
      }

  </style>
  <script>

  </script>

