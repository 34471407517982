<section class="panel">

  <div class="panel-block ">
    <div  style="height: 50px; width: 100%;">

      <div class="field has-addons is-full" >
        <div class="control is-full" style="width: 100%;">
          <input class="input" type="text" placeholder="Find suppliers by name" [(ngModel)]="searchTerm">
        </div>
        <div class="control">
          <a class="button is-primary">
            Search
          </a>
        </div>
      </div>


    </div>
  </div>
  <div class="panel-block">

  </div>
</section>

<div style="overflow-y:auto ;overflow-x: hidden;height: 500px;">
  <table class="table is-full menu-list"  >
    <tbody>

      <div class="spinner-box loadCenter is-center" *ngIf="isLoading">
        <div class="configure-border-1">
          <div class="configure-core"></div>
        </div>
        <div class="configure-border-2">
          <div class="configure-core"></div>
        </div>
      </div>
        <div *ngIf="!isLoading">
        <tr  *ngFor="let supplier of suppliers | supplierFilter: searchTerm" >
          <div class="div columns  is-full btn r" style="width: 125%;" >
              <div class="div column" >
                <table class="table is-full" style="width: 100%;">
                  <thead style="font-weight: bold;">
                    <td>SupplierID</td>
                    <td>Name </td>
                    <td>Address </td>
                    <td>City </td>
                    <td >Email</td>
                    <td >Contact</td>
                    <td >DrugsAvailable</td>
                    <td>Tin </td>
                    <td>Cst </td>
                    <td>Gst </td>
                    <td ></td>

                  </thead>
                  <tbody>
                    <td style="width: 20%;">{{ supplier.supplierID }}</td>
                    <td style="width: 20%;">{{ supplier.name }} </td>
                    <td style="width: 20%;">{{ supplier.address }} </td>
                    <td style="width: 20%;">{{ supplier.city }} </td>
                    <td style="width: 20%;">{{ supplier.email }} </td>
                    <td style="width: 20%;">{{ supplier.contact }} </td>
                    <td style="width: 20%;">{{ supplier.drugsAvailable }} </td>
                    <td style="width: 20%;">{{ supplier.tin }} </td>
                    <td style="width: 20%;">{{ supplier.cst }} </td>
                    <td style="width: 20%;">{{ supplier.gst }} </td>
                    <td class="level-right" *ngIf="userIsAuthenticated">
                      <a class="button is-small is-primary" style="font-weight: bold;margin-right: 1%;" [routerLink]="['/edit',supplier.id]">Edit </a>
                      <button class="button is-small is-danger" style="font-weight: bold;" (click)="onDelete(supplier.id)" >Delete</button>
                    </td>

                  </tbody>
                </table>
              </div>
          </div><br>
        </tr>
      </div>





    </tbody>
  </table>
  </div>
  <style>

    .menu-list{
      border-radius: 2px;
      width: 220%;
      display: block;
      padding: 10px;
      }
      .btn{
        width: 100%;
        color:hsl(0, 0%, 21%);
        background-color: hsl(0, 0%, 88%);
        border-radius: 3px;
      }
      .newclass :hover{
        background-color: hsl(0, 0%, 21%);
        color: hsl(0, 0%, 88%);
      }

  </style>
