

<div >
  <div>
    <div style="height:900px">
      <section class="section columns" style="padding:15px !important;padding-bottom:0px !important;">
        <div class="column is-full-desktop is-full-mobile">
          <!-- <app-search-supplier-window></app-search-supplier-window> -->
          <div class="tabs is-toggle is-fullwidth">
            <ul>
              <li class="is-active ">
                <a routerLink="/salesreport">
                  <!-- <span class="icon is-small"><i class="fas fa-music" aria-hidden="true"></i></span> -->
                  <span>Sales Chart</span>
                </a>
              </li>

              <li>
                <a routerLink="/salesreport/report">
                  <!-- <span class="icon is-small"><i class="fas fa-music" aria-hidden="true"></i></span> -->
                  <span>Sales Report</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>


    <section class="panel section level-item" style="margin: 3px;margin-top: -50px; width: 99%;">

          <div class="column panel is-half level-right" style="width: 50%;">

            <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
              Sales per Month
            </p>

            <div class="panel-block ">

              <div  style="height: 850px; width: 100%;">
                <div class="column">
                <div class="box">

                  <!-- <google-chart style="background-color: #21313C; border-radius: 10px;" #chart
             [title]="title"
             [type]="type"
             [data]="data"
            [columns]="columnNames"
             [options]="options"
             [width]="width"
             [height]="height">
          </google-chart> -->
          <!-- <iframe style="background: #21313C;border: none;height: 550px;;border-radius: 10px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);" width="640" height="480" src="https://charts.mongodb.com/charts-project-0-krete/public/dashboards/6488cd25-c068-4c84-8b6b-93d24592723f"></iframe> -->
          <iframe style="background: #21313C;border: none;height: 550px;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);" width="640" height="480" src="https://charts.mongodb.com/charts-project-0-krete/embed/charts?id=648dd9da-2cb9-4567-8f5c-d2a7c8a7a947&maxDataAge=3600&theme=dark&autoRefresh=true"></iframe>


                 </div>

                </div>
              </div>

            </div>
          </div>


          <div class="column panel is-half level-left" style="width: 50%;">

            <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
              Sales per Drug
            </p>

            <div class="panel-block ">

              <div  style="height: 850px; width: 100%;">
                <div class="column">
                <div class="box">
          <!-- <iframe style="background: #21313C;border: none;height: 550px;width: 100%;border-radius: 10px;box-shadow: 0 2px 40px 0 rgba(70, 76, 79, .2);" width="640" height="780" src="https://charts.mongodb.com/charts-project-0-logtf/embed/charts?id=542b4874-f7d6-4088-9041-d8c7c886f8bb&autoRefresh=10&theme=dark"></iframe> -->
          <iframe style="background: #21313C;border: none;height: 550px;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);" width="640" height="480" src="https://charts.mongodb.com/charts-project-0-krete/embed/charts?id=ca339720-7e4e-4727-9b7a-0895ae7a92c6&maxDataAge=3600&theme=dark&autoRefresh=true"></iframe>


                 </div>

                </div>
              </div>

            </div>
          </div>


      <div class="panel-block">

      </div>
    </section>
    </div>

    </div>
</div>


