<!-- <section class="panel">
  <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
    Sales Report
  </p>
  <div class="panel-block ">
    <div  style="height: 500px; width: 100%;">

      <app-sales-report-items></app-sales-report-items>

     </div>
  </div>

</section> -->

<div >
  <div>
    <div style="height:900px">
      <section class="section columns" style="padding:15px !important;padding-bottom:0px !important;">
        <div class="column is-full-desktop is-full-mobile">

          <div class="tabs is-toggle is-fullwidth">
            <ul>
              <li >
                <a routerLink="/salesreport">
                  <!-- <span class="icon is-small"><i class="fas fa-music" aria-hidden="true"></i></span> -->
                  <span>Sales Chart</span>
                </a>
              </li>

              <li class="is-active ">
                <a routerLink="/salesreport/report">
                  <!-- <span class="icon is-small"><i class="fas fa-music" aria-hidden="true"></i></span> -->
                  <span>Sales Report</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    <section class="panel" style="margin: 3px;width: 99%;">

      <div >
        <div  style="">

          <div class="panel-block ">
            <div  style="height: 700px; width: 100%;">

              <app-sales-report-items></app-sales-report-items>

             </div>
          </div>
        </div>
      </div>
      <div class="panel-block">

      </div>
    </section>
    </div>

    </div>
</div>

