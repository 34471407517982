import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-expenses-window',
  templateUrl: './list-expenses-window.component.html',
  styleUrls: ['./list-expenses-window.component.css']
})
export class ListExpensesWindowComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
