<section class="panel">
  <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
    Sales Report
  </p>
  <div class="panel-block ">
    <form (submit)="downloadcsv(daterangeForm)" #daterangeForm="ngForm" style="width:100%;">
  <label class="label" style="padding-top: 6px;float:left;font-size: 12px !important;">START DATE :&nbsp;&nbsp;</label>
    <input name="startDate" ngModel #startDateInput="ngModel" type="date" class="is-center input" style="width: 23%;float:left;font-size: 12px !important;" placeholder="071xxxxxxx" />  
    <label class="label" style="float:left;padding-top: 6px;font-size: 12px !important;">&nbsp;&nbsp;END DATE :&nbsp;&nbsp;</label>
    <input  name="endDate" ngModel #endDateInput="ngModel" type="date" class="is-center input" style="width: 23%;float:left;font-size: 12px !important;" placeholder="071xxxxxxx" />
    <button name="amount" class="button is-primary" style="float: left;padding-left:20px;font-size: 12px !important;font-weight:bold;margin-left:20px;">
      SalesAmountCSV
    </button>
    <button name="inventory" class="button is-primary" style="float: left;padding-left:20px;font-size: 12px !important;font-weight:bold;margin-left:20px;">
      SalesInventoryCSV
    </button>
  </form>
  </div>
  
  <div class="panel-block ">
    <div  style="height: 500px; width: 100%;">

      <div style="overflow-y:auto ;overflow-x: hidden;height: 500px;">
        <table  class="table is-full menu-list"  >
          <tbody>
            <div class="" >
              <tr *ngFor="let sale of sales ">
                <div class="div columns  is-full btn " style="width: 1325px;" >
                    <div class="div column" >
                      <table class="table is-full" style="width: 1300px;">
                        <thead style="font-weight: bold;">
                          <td>Drug Name</td>
                          <td>Date and Time </td>
                          <td >Total Amount </td>
                          <td >Tax </td>
                          <td >Paid Amount </td>
                          <td >Balance </td>
                          <td >Inv. No </td>
                        </thead>
                        <tbody>
                          <td>{{sale.drugName[0]}}  <br>{{sale.drugName[1]}}  <br>{{sale.drugName[2]}}  <br>{{sale.drugName[3]}}  <br>{{sale.drugName[4]}}  <br>{{sale.drugName[5]}}  {{sale.drugName[6]}}  <br>{{sale.drugName[7]}}  <br>{{sale.drugName[8]}}</td>
                          <td>{{sale.dateTime}}</td>
                          <td >{{sale.totalPrice}} </td>
                          <td >{{sale.tax}} </td>
                          <td >{{sale.paidAmount }}</td>
                          <td >{{sale.balance}} </td>
                          <td >{{sale.invoice}} </td>
                        </tbody>
                      </table>
                    </div>
                    <div class="panel-block">

                  </div>
                </div><br>
              </tr>
              <br><br>
              <tr>

              </tr>
            </div>
      <br>


          </tbody>
        </table>
        </div>

    </div>
  </div>
  <div class="panel-block">
    <button class="button is-primary   is-full" (click)="downloard()" >Save Sales Report</button>
  </div>


</section>

<div style="margin-top: 500px;">
  <table id="table" class="table is-full menu-list" style="margin-left: 20px;" >
    <tbody>
      <div class="" >
        <tr >
          <div class=" is-center">
            <p class="title is-1 is-center" style="margin-left: 30%;">Sales Report</p>
          </div><br><br>
          <div class="div columns  is-full btn " style="width: 745px;" >
              <div class="div column" >
                <table class="table is-full" style="width: 720px;">
                  <thead style="font-weight: bold;">
                    <td>Drug Name</td>
                    <td>Date and Time </td>
                    <td >Total Amount </td>
                    <td >Tax </td>
                    <td >Paid Amount </td>
                    <td >Balance </td>
                  </thead>
                  <tbody *ngFor="let sale of sales ">
                    <td>{{sale.drugName[0]}}  <br>{{sale.drugName[1]}}  <br>{{sale.drugName[2]}}  <br>{{sale.drugName[3]}}  <br>{{sale.drugName[4]}}  <br>{{sale.drugName[5]}}  {{sale.drugName[6]}}  <br>{{sale.drugName[7]}}  <br>{{sale.drugName[8]}}</td>
                    <td>{{sale.dateTime}}</td>
                    <td >{{sale.totalPrice}} </td>
                    <td >{{sale.tax}} </td>
                    <td >{{sale.paidAmount }}</td>
                    <td >{{sale.balance}} </td>
                  </tbody>
                </table>
              </div>
              <div class="panel-block">

            </div>
          </div><br>
        </tr>
        <br><br>
        <tr>

        </tr>
      </div>
<br>


    </tbody>
  </table>
</div>



  <style>

    .menu-list{
      border-radius: 2px;
      width: 220%;
      display: block;
      padding: 10px;
      }
      .btn{
        width: 100%;
        color:hsl(0, 0%, 21%);
        background-color: hsl(0, 0%, 88%);
        border-radius: 3px;
      }
      .newclass :hover{
        background-color: hsl(0, 0%, 21%);
        color: hsl(0, 0%, 88%);
      }


   table { page-break-inside:auto }
   tr    { page-break-inside:avoid; page-break-after:auto }


  </style>
