<div class="field has-addons">
  <div class="control" style="margin-right: 20%;padding-top: 3%;">
    <label class="label">Total : </label>
  </div>
  <div class="control" style="padding-top: 3%;">
    <label class="label">1231290475934875  </label>
  </div>
</div>
<br>
<div class="field has-addons">
  <div class="control" style="margin-right: 23%;padding-top: 3%;">
    <label class="label">Tax: </label>
  </div>
  <div class="control">
    <input class="input" type="text" placeholder="Enter amount">
  </div>
</div>
<br>
<div class="field has-addons">
  <div class="control" style="margin-right: 0%;padding-top: 0%;">
    <label class="label">Paid Amount :</label>
  </div>
  <div class="control">
    <input class="input" type="text" placeholder="Enter amount">
  </div>
</div>
<br>
<div class="field has-addons">
  <div class="control" style="margin-right: 15%;padding-top: 3%;">
    <label class="label">Balance :</label>
  </div>
  <div class="control" style="padding-top: 3%;">
    <label class="label">1231290475934875  </label>
  </div>
</div>
