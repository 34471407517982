import { MatSnackBar } from '@angular/material';
import { SupplierInteractionService } from './../../../a-suppliers-window/supplier-interaction.service';
import { Component, OnInit} from '@angular/core';
import {  FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Supplier } from '../../supplier.model';


@Component({
  selector: 'app-add-supplier-elements',
  templateUrl: './add-supplier-elements.component.html',
  styleUrls: ['./add-supplier-elements.component.css']
})
export class AddSupplierElementsComponent implements OnInit {

  enteredSupplierID = "";
  enteredName = "";
  enteredEmail = "";
  enteredContact = "";
  enteredDrugsAvailable = "";
  enteredNumber = "";
  supplier : Supplier;
  isLoading = false;

  form: FormGroup;
  private mode = "create";
  private supplierId : string;


 constructor(private supplierInteractionService: SupplierInteractionService, public route: ActivatedRoute, private snackBar : MatSnackBar){}


  ngOnInit() {
    this.form = new FormGroup({
      'supplierID': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'name': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'address': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'city': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'email': new FormControl(null,{validators: [Validators.required,Validators.email, Validators.minLength(1),
        Validators.pattern("[a-zA-Z0-9.-_]+@[a-zA-Z]+\.[a-zA-Z.]{2,6}$")]}),
      'contact': new FormControl(null,{validators: [Validators.required, Validators.minLength(1), phoneValidator]}),
      'drugsAvailable': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'tin': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'cst': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]}),
      'gst': new FormControl(null,{validators: [Validators.required, Validators.minLength(1)]})
    });
    this.route.paramMap.subscribe((paramMap: ParamMap)=>{
      if (paramMap.has('supplierId')){
        this.mode = "edit";
        this.supplierId = paramMap.get('supplierId');
        this.isLoading = true;
        this.supplierInteractionService.getSuppiers(this.supplierId).subscribe(supplierData =>{
        this.isLoading = false;
        this.supplier = {id:supplierData._id,
                           supplierID: supplierData.supplierID,
                           name: supplierData.name,
                           address:supplierData.address,
                           city:supplierData.city,
                           email : supplierData.email,
                           contact: supplierData.contact,
                           drugsAvailable: supplierData.drugsAvailable,
                           tin:supplierData.tin,
                           cst:supplierData.cst,
                           gst:supplierData.gst
                          };
        this.form.setValue({'supplierID':this.supplier.supplierID ,
                          'name':this.supplier.name ,
                          'address':this.supplier.address,
                          'city':this.supplier.city,
                          'email':this.supplier.email ,
                          'contact':this.supplier.contact,
                          'drugsAvailable':this.supplier.drugsAvailable,
                          'tin':this.supplier.tin,
                          'cst':this.supplier.cst,
                          'gst':this.supplier.gst
                        });

        });
      }else{
        this.mode = "create";
        this.supplierId = null;
      }
    })
  }
  get registerFormControl() {
    return this.form.controls;
  }

  onAddSupplier() {
    if (this.form.invalid) {
      return;
    }

    if(this.mode === "create"){
      this.supplierInteractionService.addSupplier(this.form.value.supplierID,
        this.form.value.name,
        this.form.value.address,
        this.form.value.city,
        this.form.value.email,
        this.form.value.contact,
        this.form.value.drugsAvailable,
        this.form.value.tin,
        this.form.value.cst,
        this.form.value.gst
        );
        this.snackBar.open("Supplier Added Successfully", "Close",{duration: 10000});
    }else{
      this.supplierInteractionService.updateSupplier(this.supplierId,this.form.value.supplierID,
        this.form.value.name,
        this.form.value.address,
        this.form.value.city,
        this.form.value.email,
        this.form.value.contact,
        this.form.value.drugsAvailable,
        this.form.value.tin,
        this.form.value.cst,
        this.form.value.gst
      );
      this.snackBar.open("Supplier Edited!! ", "Close",{duration: 10000});
    }

    this.form.reset();
  }





}

//supplier Contact validation
function emailDomainValidator(control: FormControl) { (1)
  let email = control.value; (2)
  if (email && email.indexOf("@") != -1) { (3)
    let [_, domain] = email.split("@"); (4)
    if (domain !== "gmail.com") { (5)
      return {
        emailDomain: {
          parsedDomain: domain
        }
      }
    }
  }
  return null; (6)
}
function phoneValidator(control: FormControl) { (1)
  let phone = control.value; (2)
  var phonearray =[];
  var pattern = new RegExp('^[0-9]{10,12}$');
  if (phone) { (3)
    phonearray = phone.split(","); (4)
    for(var i=0;i<phonearray.length;i++)
    {
      var result=pattern.test(phonearray[i]);
          if (!result) { (5)
          return {
            phoneNumber: {
              parsedomain: phonearray[i]
            }
          }
        }
    }
  }
  return null; (6)
}
