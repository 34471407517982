<section class="panel">
  <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
    Out of Stock Notification
  </p>
  <div class="panel-block ">
    <div  style="height: 200px; width: 100%;">

      <app-outofstock-window-item></app-outofstock-window-item>

    </div>
  </div>
  <div class="panel-block">
    <button class="button is-primary   is-fullwidth">
      View Out of Stock Notifications
    </button>
  </div>
</section>
