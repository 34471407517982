<!-- <section class="panel" style="margin-top: 20%;">
  <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
    About To Expire Drugs
  </p>
  <div class="panel-block ">
    <div  style="height: 500px; width: 100%;">

      <app-about-to-expire-items></app-about-to-expire-items>

    </div>
  </div>
  <div class="panel-block">

  </div>
</section> -->

<div style="margin-top: 5%;">
  <div style="margin-top: 2%; height:900px">
    <section class="section columns">
      <div class="column is-full-desktop is-full-mobile">
        <!-- <app-search-supplier-window></app-search-supplier-window> -->
        <div class="tabs is-toggle is-fullwidth">
          <ul>
            <li >
              <a routerLink="/expoutofstock">
                <!-- <span class="icon is-small"><i class="fas fa-music" aria-hidden="true"></i></span> -->
                <span>Expired Drugs</span>
              </a>
            </li>

            <li class="is-active ">
              <a routerLink="/expoutofstock/abouttoexpire/">
                <!-- <span class="icon is-small"><i class="fas fa-music" aria-hidden="true"></i></span> -->
                <span>About To Expire Drugs</span>
              </a>
            </li>


          </ul>
        </div>
      </div>
    </section>
  <section class="panel" style="margin: 3px;margin-top: -30px; width: 99%;">


      <p class="panel-heading" style="background-color:hsl(0, 0%, 88%) ;color:hsl(0, 0%, 21%)">
        About To Expire Drugs
      </p>
      <div class="panel-block ">
        <div  style="height: 500px; width: 100%;">

          <app-about-to-expire-items></app-about-to-expire-items>

        </div>
      </div>
      <div class="panel-block">

      </div>
    </section>

  </div>

  </div>

